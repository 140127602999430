import { createRouter, createWebHistory } from "@ionic/vue-router";
import HomeView from "../views/HomeView.vue";
import { useAuthStore } from "@/stores/auth";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      redirect: { name: "home" },
    },
    {
      path: "/feed",
      name: "home",
      component: HomeView,
      meta: { public: false },
    },
    {
      path: "/about",
      name: "about",
      meta: { public: true },
      component: () => import("../views/AboutView.vue"),
    },
    {
      path: "/login",
      name: "login",
      meta: { public: true },
      component: () => import("../views/LoginView.vue"),
    },
    {
      path: "/:username",
      name: "profile",
      meta: { public: false },
      component: () => import("../views/ProfilePage.vue"),
    },
    {
      path: "/:username/channel",
      name: "Channel",
      meta: { public: false },
      component: () => import("../views/UserChannel.vue"),
    },
    {
      path: "/notifications",
      name: "notifications",
      meta: { public: false },
      component: () => import("../views/NotificationPage.vue"),
    },
    {
      path: "/:username/connections",
      name: "connections",
      meta: { public: false },
      component: () => import("../views/UserConnections.vue"),
    },
    {
      path: "/mute_block",
      name: "mute_block",
      meta: { public: false },
      component: () => import("../views/MuteBlock.vue"),
    },
    {
      path: "/policy",
      name: "policy",
      meta: { public: true },
      component: () => import("../views/PolicyDocuments.vue"),
    },
    {
      path: "/setup",
      name: "setup",
      meta: { public: false },
      component: () => import("../views/AccountSetup.vue"),
    },
    {
      path: "/post/:id",
      name: "post",
      meta: { public: false },
      component: () => import("../views/DetailsPage.vue"),
    },
    {
      path: "/settings",
      name: "settings",
      meta: { public: false },
      component: () => import("../views/UserSettings.vue"),
    },
    {
      path: "/search",
      name: "SearchDefault",
      meta: { public: false },
      component: () => import("../views/SearchPage.vue"),
    },
    {
      path: "/:searchType/:query",
      name: "Search",
      meta: { public: false },
      component: () => import("../views/SearchPage.vue"),
    },
    {
      path: "/trending",
      name: "Trending",
      meta: { public: false },
      component: () => import("../views/Trending.vue"),
    },
    {
      path: "/violations",
      name: "Violations",
      meta: { public: false },
      component: () => import("../views/UserViolations.vue"),
    },
    {
      path: "/recommendations",
      name: "Recommendations",
      meta: { public: false },
      component: () => import("../views/RecommendedFollows.vue"),
    },
    {
      path: "/groups",
      name: "Groups",
      component: () => import("../views/GroupHome.vue"),
    },
    {
      path: "/group/:id",
      name: "Group",
      component: () => import("../views/GroupPage.vue"),
    },
    {
      path: "/group/:groupId/members",
      name: "GroupMembers",
      component: () => import("../views/GroupMembers.vue")
    },
    {
      path: "/video/:id",
      name: "Video",
      component: () => import("../views/VideoPage.vue"),
    }
  ],
});

let isRedirecting = false;

const redirectToLogin = (to, next) => {
  next({ path: "/login", query: { redirect: to.fullPath } });
  isRedirecting = true;
};

const redirectToSetup = (next) => {
  next({ path: "/setup" });
  isRedirecting = true;
}

const redirectToPolicy = (next) => {
  next({ path: "/policy" });
  isRedirecting = true;
}

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();
  const currentUser = authStore.currentUser;

  if (currentUser) {
    if (!isRedirecting && !to.meta.public && !authStore.authenticated) {
      redirectToLogin(to, next); // Pass `to` to the function
    } else if (
      !isRedirecting &&
      authStore.authenticated &&
      !currentUser.accountSetup
    ) {
      redirectToSetup(next); // Redirect to account setup page
    } else if (
      authStore.authenticated &&
      !isRedirecting &&
      !currentUser.acceptTermsOfService
    ) {
      redirectToPolicy(next); // Redirect to policy page
    } else {
      next(); // Continue with the navigation
    }
  } else if (!to.meta.public) {
    redirectToLogin(to, next); // If there's no currentUser and route isn't public, redirect to login
  } else {
    next(); // Continue with the navigation for public routes
  }
});

router.onError((error, to) => {
  if (
    error.message.includes("Failed to fetch dynamically imported module") ||
    error.message.includes("Importing a module script failed")
  ) {
    window.location.href = to.fullPath;
  }
});

// iOS PWA specific handling
if ("scrollRestoration" in history) {
  history.scrollRestoration = "manual";
}

export default router;
