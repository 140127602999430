<template>
     <RecycleScroller
      v-if="feedPosts"
      ref="scroller"
      class="scroller pb-56"
      v-slot="{ item, index }"
      :items="feedPosts"
      :item-secondary-size="gridItems === null ? 280 : 330"
      :item-size="gridItems === null ? 300 : 280"
      :grid-items="gridItems"
      key-field="id"
      @scroll="handleScroll"
    > 
      <div class="video-item" v-if="item" :key="item.id">
        <VideoThumbail :item="item" />
      </div>
    </RecycleScroller>
    <div v-if="atBottom" class="flex items-center justify-center w-full py-4 text-black dark:text-white">
      <ion-spinner v-if="isLoadingMore" name="crescent" class="absolute bottom-36 md:bottom-8 text-center w-fulltext-7xl" />
      <p v-else-if="endOfFeed" class="absolute bottom-36 md:bottom-8 text-center text-lg w-full">
        End of feed
      </p>
    </div>
</template>

<script setup>
import { ref, computed, onBeforeMount, watch, onBeforeUnmount } from 'vue';
import { IonSpinner } from '@ionic/vue';
import { useGlobalStore } from '@/stores/global';
import { usePostStore } from '@/stores/post';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import { RecycleScroller } from 'vue-virtual-scroller';
import VideoThumbail from './VideoThumbail.vue';

const props = defineProps({
  feedType: String,
  feedParams: String,
  searchType: String,
});

const postStore = usePostStore();
const globalStore = useGlobalStore();
const isLoading = ref(true);
const isLoadingMore = ref(false);
let next_cursor = null;
const scrolledTop = ref(false);
let lastScrollTop = 0;
let scrollDownCounter = 0;
let scrollUpCounter = 0;
const consecutiveScrollsRequired = 3;
let toolbarHidden = ref(false);
let lastCalledTime = null;
const throttleDuration = 200;
let lastPostPotition = 0;
let triggerIndex = 0;
const pageSize = 20;
const gridItems = ref(4);
const atBottom = ref(false);
const scroller = ref(null);
const searchType = ref(null);
const feedParams = ref(null);

const emit = defineEmits(["refreshComplete", "toggleVisibility", "toolBar", "userId"]);

const endOfFeed = computed(() => {
  return feedPostIds.value.length > 0 && next_cursor === null && atBottom.value;
});

watch(
  () => props.feedType,
  async (newFeedType, oldFeedType) => {
    if (newFeedType !== oldFeedType) {
      scrollToTop();
      isLoadingMore.value = true;

      // fetch initial posts from cache for new feed type

      await loadFeed();

      hasMoreItems.value = true;

      isLoadingMore.value = false;

    }
  }
);

async function scrollToTop() {
  if (scroller.value?.$el) {
    scroller.value.$el.scrollTop = 0;
  }
}

const feedMapKey = computed(() => {
  let feedParam;
  if (props.feedType === "profile" && props.searchType === "videos") {
    feedParam = `${props.feedType}-${props.feedParams}-videos`;
  } else {
    feedParam = 'videos-' + props.feedType;
  }
  return feedParam;
});

const feedPostIds = computed(() => {
  // Check if the feedMap for the feedParam exists and has length
    console.log(feedMapKey.value);
  if (
    !postStore.feedMaps[feedMapKey.value] ||
    postStore.feedMaps[feedMapKey.value].length === 0
  ) {
    return [];
  }
  const feedMap = postStore.feedMaps[feedMapKey.value];

  return postStore.feedMaps[feedMapKey.value] || [];
});

const feedPosts = computed(() => {

  if (!feedPostIds.value) {
    return [];
  }
  // Start with an empty array
  let computedFeed = [];

  let computedFeedIds = [];

  computedFeedIds = feedPostIds.value;

  
    // Append the rest of the posts
    computedFeed = computedFeed.concat(
      computedFeedIds
        .map((postId) => postStore.postsCache[postId])
        .filter((post) => post)
    );

    if (props.feedType === "profile") {
    const firstPost = computedFeed[0];
    if (firstPost) {
      console.log(firstPost.user.userId);
      emit("userId", firstPost.user.userId);
    }
  }

  // Return the final array
  return computedFeed;
});

watch(
  () => feedPosts.value,
  async (newValue, oldValue) => {
    if (newValue.length > 0) {
      isLoading.value = false;
    }
  }
);

const suffix = computed(() => `${props.feedParams}-${props.searchType}`);

const feedKey = computed(() => {
  return props.feedType === "profile" || props.feedType === "search"
    ? `${props.feedType}-${suffix.value}`
    : props.feedType;
});

onBeforeMount(async () => {
  await loadFeed();
  onResize();
  window.addEventListener('resize', onResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize);
});

function onResize() {
  let containerWidth = scroller.value.$el.clientWidth;

  if (containerWidth >= 1200) { // lg
    gridItems.value = 4;
  } else if (containerWidth >= 768) { // md
    gridItems.value = 3;
  } else if (containerWidth >= 450) { // md
    gridItems.value = 2;
  } else { // sm
    gridItems.value = null;
  }
}

async function loadFeed() {

  isLoadingMore.value = true;

  // fetch data from server async
  let feedData = await postStore.fetchFeedData(
    props.feedType,
    props.feedParams,
    props.searchType
  );
  
  next_cursor = feedData.next_cursor;

  const posts = feedData.posts;

  isLoadingMore.value = false;
  isLoading.value = false;
}

function handleScroll(event) {
  // return;

  if (!event.target) {
    return;
  }
  const { scrollTop, scrollHeight, clientHeight } = event.target;

  // Calculate the distance from the bottom
  const scrollBottom = scrollHeight - scrollTop - clientHeight;

  // Calculate 30% of the clientHeight
  const triggerDistance = clientHeight * 0.3;

  if (scrollTop === 0) {
    scrolledTop.value = true;
    // Reset counters when reaching the top
    scrollDownCounter = 0;
    scrollUpCounter = 0;
    emit("toolBar", true);
    emit("toggleVisibility", true);
    toolbarHidden.value = false;
  } else if (scrollBottom <= triggerDistance) {
    // Load more posts when 30% or less away from the bottom
    if (!isLoadingMore.value) {
      loadMorePosts();
    }
    atBottom.value = true;
  } else {
    scrolledTop.value = false;
    atBottom.value = false;
  }

  // Determine scroll direction and update counters
  if (scrollTop > lastScrollTop) {
    // Scrolling down
    scrollDownCounter++;
    scrollUpCounter = 0; // Reset scroll up counter

    if (scrollDownCounter >= consecutiveScrollsRequired) {
      emit("toolBar", false);
      toolbarHidden.value = true;
      scrollDownCounter = 0; // Reset counter after triggering
    }
  } else if (scrollTop < lastScrollTop) {
    // Scrolling up
    scrollUpCounter++;
    scrollDownCounter = 0; // Reset scroll down counter

    if (scrollUpCounter >= consecutiveScrollsRequired) {
      emit("toolBar", true);
      toolbarHidden.value = false;
      scrollUpCounter = 0; // Reset counter after triggering
    }
  }

  // Handle specific behavior for profile feed
  if (
    globalStore.currentFeed !== "influencers" ||
    props.feedType === "profile"
  ) {
    emit("toggleVisibility", scrollTop === 0);
  }

  // Update lastScrollTop for the next scroll event
  lastScrollTop = scrollTop;
}

async function loadMorePosts() {
  isLoadingMore.value = true;

  // Check if the function was called within the throttle duration
  if (lastCalledTime && Date.now() - lastCalledTime < throttleDuration) {
    isLoadingMore.value = false;
    return;
  }

  lastCalledTime = Date.now();
  // Exit the function if next_cursor is null
  if (next_cursor === null) {
    isLoadingMore.value = false;
    return;
  }

  lastPostPotition += postStore.sliceFeed;
  let returnData = await postStore.fetchFeedData(
    props.feedType,
    props.feedParams,
    props.searchType,
    lastPostPotition,
    null,
    pageSize,
    next_cursor
  );
  let posts = returnData.posts;

  // set next_cursor for pagination
  next_cursor = returnData.next_cursor;

  // If there are no more posts, return lastposition to previous value
  if (posts.length === 0) {
    lastPostPotition -= postStore.sliceFeed;
    isLoadingMore.value = false;
    return;
  }
  // Filter out posts that already exist in feedPostIds
  const uniqueNewPosts = posts.filter(
    (postUlid) => !feedPostIds.value.includes(postUlid)
  );

  // Add only unique new posts to feed
  feedPostIds.value.push(...uniqueNewPosts.map((post) => post.id));

  triggerIndex = feedPostIds.value.length - 20;

  // complete infinite scroll
  // ev.target.complete();

  isLoadingMore.value = false;
}

</script>

<style scoped>
.scroller {
  display: grid;
  gap: 1rem;
  height: 100%;
  width: 100%;
  overflow-y: auto; /* Ensure vertical scrolling is enabled */
}
</style>
