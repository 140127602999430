<template>
  <div class="logo-wrapper flex flex-row items-center cursor-pointer pl-1 pr-1">

    <div v-if="!isBanned" v-for="badge of  badges " :key="badge">
      <div v-if="badge" :id="['badges' + username]"
        :class="badgeColorClass(badge), { 'w-3 h-3': isCommentDetails, 'rotate-svg': isMarc }" :title="capitalize(badge)"
        class="badge border-[2px] border-gray-200 rounded-full text-white w-5 h-5 -mr-1 drop-shadow-sm cursor-pointer select-none"
        @click.stop.prevent="openBadgePopover($event)">
        <div class="flex items-center justify-center h-full">
          <ion-icon :icon="badgeIcon(badge)" :class="{ 'text-xl': isCommentDetails }" />
        </div>
      </div>
    </div>
    <div v-if="isCurrentUserEmployee && showAdmin" class="pl-2">
      <button @click.stop.prevent="openManageBadgePopover($event)" :id="['manage' + username]"><ion-icon
          class="text-2xl align-middle cursor-pointer select-none" :icon="cogSharp" /></button>
    </div>
  </div>

  <!-- Additional popover for managing badges -->
  <ion-popover :is-open="showManageBadgePopover" :event="manageBadgeEvent" @didDismiss="closeManageBadgePopover"
    alignment="center">
    <ion-list nested lines="none" class="cursor-pointer select-none">
      <ion-item v-for=" badge  of  uniqueBadgesData " :key="badge.name">
        <ion-label>{{ badge.name === 'infuencer' ? 'influencer' : badge.name }}</ion-label>
        <ion-button @click="addOrRemoveBadge(badge.name)">
          {{ badges && badges.includes(badge.name) ? 'Remove' : 'Add' }}
        </ion-button>
      </ion-item>
      <ion-item v-if="isCurrentUserEmployee && isCurrentUserModerator && authStore.currentUser.ulid !== userId &&
        !isEmployee && !areYouSure">
        <ion-button @click="comfirm"><ion-icon :icon="moderationIcon"
            class="align-middle pr-1 cursor-pointer select-none" />
          <ion-label>{{ isBanned ? 'Unban User' : 'Ban User' }}</ion-label></ion-button>
      </ion-item>
      <ion-item v-if="isCurrentUserEmployee && isCurrentUserModerator && authStore.currentUser.ulid !== userId &&
        !isEmployee && areYouSure
        ">
        <div class="border-2 border-red-500 rounded-lg p-2 w-full">
          <p class="text-sm pb-2">Are you sure you want to {{ isBanned ? 'unban' : 'ban' }} this user?</p>
          <ion-button @click="adminBanAndUnban"><ion-icon :icon="moderationIcon"
              class="align-middle cursor-pointer select-none" />
            <ion-label>{{ isBanned ? 'Unban User' : 'Ban User' }}</ion-label></ion-button>
        </div>
      </ion-item>
    </ion-list>
  </ion-popover>

  <!-- Ionic Popover -->
  <ion-popover :is-open="showBadgePopover" :keep-contents-mounted="true" :event="popoverEvent"
    @didDismiss="showBadgePopover = false" alignment="center">
    <ion-list nested lines="none" class="firstPop grid-cols-2">
      <div :id="[badge + username]" v-for="    badge     of     badges    " :key="badge"
        class="flex flex-col items-center justify-center m-2 cursor-pointer select-none"
        @click.stop.prevent="openBadgeDesc($event, badge)">
        <div :class="badgeColorClass(badge)" :title="capitalize(badge)"
          class="badge border-[4px] border-gray-200 rounded-full text-white w-24 h-24 items-center justify-center drop-shadow-md">
          <div class="flex items-center justify-center text-6xl mt-4">
            <ion-icon :icon="badgeIcon(badge)" class="align-middle nested-trigger" />
          </div>
        </div>
        <div class="text-black dark:text-white">{{ capitalize(badge) }}</div>
      </div>
    </ion-list>
  </ion-popover>

  <ion-popover :is-open="showBadgeDesc" :keep-contents-mounted="true" :event="descEvent"
    @didDismiss="showBadgeDesc = false" :dismiss-on-select="true" side="end">
    <div class="text-black dark:text-white popover-content">
      {{ badgeDesc(selectedBadge) }}
    </div>
  </ion-popover>
</template>

<script setup>
import { useUserStore } from "../stores/users";
import { useAuthStore } from "../stores/auth"
import { IonIcon, IonPopover, IonList, IonItem, IonLabel, IonButton } from "@ionic/vue";
import {
  buildSharp,
  checkmarkSharp,
  rocketSharp,
  megaphoneSharp,
  starSharp,
  constructSharp,
  cogSharp,
  helpSharp,
  pawSharp
} from "ionicons/icons";
import moderationIcon from "@/assets/moderation_icon.svg";
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import { useGlobalStore } from "../stores/global";
import { useAdminStore } from "../stores/admin";


const props = defineProps({
  userId: {
    type: String,
    required: false,
  },
  username: {
    type: String,
    required: false,
  },
  showAdmin: {
    type: Boolean,
    default: false,
    required: false,
  },
  isCommentDetails: {
    type: Boolean,
    default: false,
    required: false,
  },
  isBanned: {
    type: Boolean,
    default: false,
    required: false,
  },
});

const userStore = useUserStore();
const authStore = useAuthStore();
const globalStore = useGlobalStore();
const adminStore = useAdminStore();
const areYouSure = ref(false);

const comfirm = () => {
  areYouSure.value = true;
};

const badges = computed(() => {
  return props.userId ? userStore.getBadges(props.userId) : "";
});


const isEmployee = computed(() => {
  return badges.value && badges.value.includes("employee" || "administrator");
});

const isMarc = computed(() => {
  return props.username === "Marc";
});

const isSean = computed(() => {
  return props.username === "uglydawg";
});

async function adminBanAndUnban() {

  let response;

  if (props.isBanned) {
    // Unban the user
    response = await adminStore.unbanUser(props.userId);
  } else {
    // Ban the user
    response = await adminStore.banUser(props.userId);
  }
  if (response.success) {
    // Display success message
    alert(`${props.isBanned ? 'Unbanned' : 'Banned'} user successfully`);
    userStore.fetchUsersFromApi([props.userId]);
    // Close the popover
    showManageBadgePopover.value = false;
  } else {
    // Handle the error case - display the error message
    alert(`The user was not ${props.isBanned ? 'unbanned' : 'banned'} due to an error`);
  }
}

const badgesData = ref([]);

onMounted(() => {
  document.addEventListener("click", (event) => {
    if (!popoverEvent.value) return;
    const popover = document.querySelector("ion-popover");
    if (!popover.contains(event.target) && event.target !== popoverEvent.value) {
      closeBadgePopover();
    }
  });
  if (globalStore.badgeTypes.length === 0) {
    globalStore.fetchBadgeTypes();
  }
  badgesData.value = globalStore.badgeTypes;
});

onBeforeUnmount(() => {
  if(showBadgePopover.value) {
  document.removeEventListener("click", () => {
    closeBadgePopover();
  });
}
});


// Computed property to check if the current user is an employee
const isCurrentUserEmployee = computed(() => {
  return authStore.currentUser.badges && authStore.currentUser.badges.includes('employee');
});

const isCurrentUserModerator = computed(() => {
  return authStore.currentUser.badges && authStore.currentUser.badges.includes('moderator');
});

const uniqueBadgesData = computed(() => {
  const uniqueNames = new Set();
  return badgesData.value.filter(badge => {
    if (!uniqueNames.has(badge.name)) {
      uniqueNames.add(badge.name);
      return true;
    }
    return false;
  });
});

// New popover state
const showManageBadgePopover = ref(false);
const manageBadgeEvent = ref(null);

const openManageBadgePopover = (event) => {
  manageBadgeEvent.value = event;
  showManageBadgePopover.value = true;
};

const closeManageBadgePopover = () => {
  showManageBadgePopover.value = false;
  areYouSure.value = false;
};


// Method to add or remove a badge
const addOrRemoveBadge = async (badgeName) => {

  const method = badges.value && badges.value.includes(badgeName) ? 'DELETE' : 'POST';
  const action = method === 'DELETE' ? 'Removed' : 'Added';
  const response = await adminStore.addOrRemoveBadge(props.username, badgeName, method);

  if (response.success) {
    // Display success message
    alert(`${action} ${badgeName} badge successfully`);
    userStore.fetchUsersFromApi([props.userId]);

    // Close the popover
    showManageBadgePopover.value = false;
  } else {
    // Handle the error case - display the error message
    alert("The ${badgeName} badge was not ${action} due to an error");
  }
};

const badgeIcon = (name) => {
  switch (name) {

    case "employee":
      return constructSharp;
    case "moderator":
      return moderationIcon;
    case "early_adopter":
      return rocketSharp;
    case "verified":
      return isSean.value ? pawSharp : checkmarkSharp;
    case "premium_verified":
      return starSharp;
    case "influencer":
      return megaphoneSharp;
    case "administrator":
      return buildSharp;

    default:
      return helpSharp;
  }
};

const badgeColorClass = (name) => {
  switch (name) {
    case "employee":
      return "bg-gradient-to-r from-blue-500 to-purple-500";
    case "moderator":
      return "bg-gradient-to-r from-red-500 to-purple-500";
    case "early_adopter":
      return "bg-gradient-to-r from-orange-500 to-purple-500";
    case "verified":
      return "bg-gradient-to-r from-blue-500 to-purple-500 font-bold";
    case "premium_verified":
      return "bg-gradient-to-r from-yellow-500 to-purple-500 font-bold";
    case "infuencer":
      return "bg-gradient-to-r from-yellow-500 to-orange-500";
    case "administrator":
      return "bg-gradient-to-r from-red-500 to-yellow-500";
    default:
      return "bg-gray-500";
  }
};

const badgeDesc = (name) => {
  // Find the badge object in badgesData array
  const badgeInfo = badgesData.value.find(badge => badge.name === name);
  // Return the description if found, else a default message
  return badgeInfo ? badgeInfo.description : "No description available for this badge.";
};

const capitalize = (str) => {
  return str
    .split('_') // Split by underscore
    .map(word => word
      .split(' ') // Split by space in case there are spaces
      .map(w => w.charAt(0).toUpperCase() + w.slice(1)) // Capitalize each word
      .join(' ')
    )
    .join(' '); // Join them back together with a space
};

// Popover state
const showBadgePopover = ref(false);
const popoverEvent = ref(null);
const showBadgeDesc = ref(false);
const descEvent = ref(null);
const selectedBadge = ref(null);

const openBadgePopover = (event) => {
  popoverEvent.value = event;
  showBadgePopover.value = true;
};

const closeBadgePopover = () => {
  showBadgePopover.value = false;
};
const openBadgeDesc = (event, badge) => {
  descEvent.value = event;
  showBadgeDesc.value = true;
  selectedBadge.value = badge;
};

</script>
<style scoped>
.popover-content {
  padding: 10px;
  max-width: 300px;
}

ion-popover,
.firstPop {
  --width: 325px !important;
}

.rotate-svg ion-icon {
  transform: rotate(135deg);
  /* Adjust the degree of rotation as needed */
}
</style>
