<template>
  <div class="relative w-full mr-3 text-white">
    <div class="flex w-full items-center ml-2" :class="searchPage ? 'mr-1' : ''">
      <ion-searchbar
        search-icon="undefined"
        ref="searchBarRef"
        @ionBlur="handleBlur"
        show-clear-button="always"
        @ionClear="handleCancel"
        class="h-[41px] w-full"
        v-model="searchInput"
        placeholder="Search..."
        @input="handleSearchInput"
        @keyup.enter="onEnterPressed"
      />
      <SearchIcon v-if="!searchPage"
        class="shrink-0"
        @click.stop.prevent="quickSearch(searchQuery, searchType)"
        :active="true"
        :height="32"
        :width="32"
      />
    </div>
    <div
      v-if="
        (userSearch.length > 0 || hashtagSearch.length > 0 || tickerSearch.length > 0) &&
        showQuickSearch &&
        !searchPage
      "
      class="absolute top-12 left-1 z-50 w-full rounded-t-sm rounded-b-lg pt-1 shadow-lg"
    >
      <ion-list
        v-if="showQuickSearch && userSearch && userSearch.length > 0"
        class="w-full"
      >
        <ion-item
          v-for="user in userSearch.slice(0, 5)"
          :key="user.ulid"
          class="flex w-full cursor-pointer select-none"
          lines="none"
          @click="navigateToProfile(user.username)"
        >
          <ion-avatar class="h-8 w-8" slot="start">
            <UserAvatar :avatar="user.avatar" />
          </ion-avatar>
          <div class="flex-cols w-full">
            <div class="flex space-x-1">
              <h2 class="max-w-[40vw] truncate">{{ user.name }}</h2>
              <ParlerBadges :userId="user.ulid" :username="user.username" />
            </div>
            <h3>@{{ user.username }}</h3>
          </div>
          <!-- <p v-if="user.ProfileEngagement.isFollowingYou === true">follows you</p> -->

          <!-- <ion-button fill="clear" @click="unfollowUser(following.username)">
                              Unfollow
                          </ion-button> -->
          <button
            @click.stop.prevent="quickSearch(user.ulid, 'username')"
            class="ml-3 rounded-lg bg-secondary text-xl text-white p-[3px] pl-2 pr-2 align-middle cursor-pointer select-none"
          >
            <ion-icon :icon="searchSharp" />
          </button>
        </ion-item>
        <p
          v-if="userSearch.length > 5"
          class="text-center text-blue-500 font-bold w-full p-2"
          @click.stop.prevent="quickSearch(searchQuery, 'username')"
        >
          Show More
        </p>
      </ion-list>

      <ion-list
        v-if="showQuickSearch && hashtagSearch && hashtagSearch.length > 0"
        class="cursor-pointer select-none"
      >
        <ion-item
          v-for="hashtag in hashtagSearch.slice(0, 5)"
          :key="hashtag.name"
          @click="quickSearch(hashtag.name, 'hashtag')"
        >
          <ion-label>
            <h2>#{{ hashtag.name }}</h2>
          </ion-label>
        </ion-item>
        <p
          v-if="hashtagSearch.length > 5"
          class="text-center text-blue-500 font-bold w-full p-2"
          @click.stop.prevent="quickSearch(searchQuery, 'hashtag')"
        >
          Show More
        </p>
      </ion-list>
      <ion-list v-if="showQuickSearch && tickerSearch && tickerSearch.length > 0">
        <ion-item v-for="ticker in tickerSearch.slice(0, 5)" :key="ticker.name">
          <ion-label @click="quickSearch(ticker.name, 'ticker')">
            <h2>${{ ticker.name }}</h2>
          </ion-label>
        </ion-item>
        <p
          v-if="tickerSearch.length > 5"
          class="text-center text-blue-500 font-bold w-full p-2"
          @click.stop.prevent="quickSearch(searchQuery, 'ticker')"
        >
          Show More
        </p>
      </ion-list>
    </div>
    <ion-list v-if="userSearch && userSearch.length > 0"> </ion-list>
  </div>
</template>

<script setup>
import { usePostStore } from "@/stores/post";
import { useUserStore } from "@/stores/users";
import SearchIcon from "@/components/tenantcomponents/parler/icons/SearchIcon.vue";
import ParlerBadges from "./ParlerBadges.vue";
import UserAvatar from "@/components/UserAvatar.vue";
import { ref, onMounted, watch, computed, nextTick, onBeforeUnmount } from "vue";
import { useRouter } from "vue-router";
import { search, searchSharp } from "ionicons/icons";
import { IonAvatar, IonIcon, IonList, IonItem, IonLabel, IonSearchbar } from "@ionic/vue";

const props = defineProps({
  searchPage: {
    type: Boolean,
    default: false,
  },
  searchInputDefault: {
    type: String,
    default: "",
  },
  currentSearchType: {
    type: String,
    default: "",
  },
  currentSearchInput: {
    type: String,
    default: "",
  },
});

const searchQuery = ref("");

const postStore = usePostStore();
const usersStore = useUserStore();
const searchType = ref(props.currentSearchType || "posts");
const userSearch = ref([]);
const hashtagSearch = ref([]);
const tickerSearch = ref([]);
const searchExecuted = ref(false);
const router = useRouter();
const searchInput = ref(props.searchInputDefault || "");
const searchBarRef = ref(null);

watch(
  () => props.currentSearchInput,
  (newValue) => {
    searchInput.value = newValue;
    searchQuery.value = newValue;
  },
  { immediate: true }
);

const navigateToProfile = (username) => {

  const encodedUsername = encodeURIComponent(username);
  
  router.push(`/${encodedUsername}`);
};

const showQuickSearch = computed(() => {
  return searchInput.value.length > 2;
});

const onEnterPressed = () => {
  quickSearch(searchQuery.value, searchType.value);

  // Attempt to blur all input elements
  document.activeElement.blur();
};

const debouncedUpdateQuickSearch = debounce(async () => {
  userSearch.value = [];
  hashtagSearch.value = [];
  tickerSearch.value = [];

  if (searchQuery.value.length < 2) {
    return;
  }

  try {
    const searchResults = await usersStore.newSearchUsers(searchQuery.value);
    if (!searchResults) {
      return;
    }
    userSearch.value = searchResults.map((user) => {
      return { ...user };
    });
  } catch (error) {
    console.error("Error updating user search results:", error);
  }

  try {
    const searchResults = await postStore.searchHashtags(searchQuery.value);
    hashtagSearch.value = searchResults;
  } catch (error) {
    console.error("Error updating hashtag search results:", error);
  }

  try {
    const searchResults = await postStore.searchTickers(searchQuery.value);
    tickerSearch.value = searchResults;
  } catch (error) {
    console.error("Error updating ticker search results:", error);
  }
}, 1000); // 1 second

function debounce(func, wait) {
  let timeoutId = null;

  function debounced(...args) {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => func(...args), wait);
  }

  debounced.cancel = function () {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }
  };

  return debounced;
}

const emit = defineEmits(["close", "clear-search", "new-search", "quick-search"]);

let blurTimeout = null;

function handleBlur() {
  if (!props.searchPage) {
    blurTimeout = setTimeout(() => {
      emit("close");
    }, 500);
  } 
  // else {
  //   emit("new-search", searchQuery.value, searchType.value);
  // }
}

function handleCancel() {
  searchInput.value = "";
  searchQuery.value = "";
  userSearch.value = [];
  hashtagSearch.value = [];
  tickerSearch.value = [];
  searchExecuted.value = false;
  showQuickSearch.value = false;
  if (props.searchPage) {
    emit("clear-search");

    return;
  }
  emit("close");
}


watch(
  () => searchQuery.value,
  () => {
    // if (searchQuery.value !== searchType.value) {
    //   searchType.value = searchQuery.value;
    // }
    debouncedUpdateQuickSearch();
  },
  { immediate: false }
);

const quickSearch = (query, type) => {
  if (query.length === 0) {
    return;
  }
  if (props.searchPage) {
    if (type === "username" || type === "hashtag" || type === "ticker") {
      // If the searchType is specific (hashtag, ticker, or username), emit it with the type.
      emit("new-search", query, type);
    } else {
      // If the input does not start with @, #, or $, emit only the query without type.
      emit("new-search", query);
    }
    showQuickSearch.value = false;

    return;
  }

  router.push(`/${type}/${query}`);

  searchExecuted.value = true;
  userSearch.value = [];
  hashtagSearch.value = [];
  tickerSearch.value = [];
};

const handleSearchInput = () => {
  const query = searchInput.value;

  if (query.startsWith("#")) {
    searchType.value = "hashtag";
    searchQuery.value = query.slice(1);
    searchInput.value = query;
  } else if (query.startsWith("@")) {
    searchType.value = "username";
    searchQuery.value = query.slice(1);
    searchInput.value = query
  } else if (query.startsWith("$")) {
    searchType.value = "ticker";
    searchQuery.value = query.slice(1);
    searchInput.value = query;
  } else {
    if (props.currentSearchType) {
      searchType.value = props.currentSearchType;
    } else {
      searchType.value = "username";
    }
    searchQuery.value = query;
  }
  emit("quick-search", searchQuery.value, searchType.value);
};

let focusTimeout = null;

onMounted(() => {
  nextTick(() => {


    if (!props.searchPage) {
      focusTimeout = setTimeout(() => {
        searchBarRef.value.$el.setFocus();
      }, 1000);
    }
  });
});

onBeforeUnmount(() => {
  debouncedUpdateQuickSearch.cancel();
  userSearch.value = [];
  hashtagSearch.value = [];
  tickerSearch.value = [];
  clearTimeout(focusTimeout);
  clearTimeout(blurTimeout);
});
</script>

<style scoped>
ion-searchbar.search {
  --wdith: 100%;
  --border-radius: 0px;
}
</style>
