<template>
  <div v-if="item" :data-id="item.id" class="p-2 md:p-8">
     <div class="w-full aspect-w-16 aspect-h-9 relative flex justify-center items-center rounded-lg overflow-hidden" v-if="item?.videos[0]?.thumbnail?.url" @click="openVideo">
      <img :src="item?.videos[0]?.thumbnail?.url" alt="Video Thumbnail" class="h-full w-[96%] object-fill absolute  cursor-pointer opacity-80"  />
      <img :src="item?.videos[0]?.thumbnail?.url" alt="Video Thumbnail" class="h-full object-cover cursor-pointer z-10" />
      <div class="absolute inset-0 flex items-center justify-center">
        <ion-icon :icon="playSharp" class="text-white text-5xl cursor-pointer z-20" @click="openVideo"></ion-icon>
      </div>
    </div>
    <div v-else class="w-full aspect-w-16 aspect-h-9 flex items-center justify-center bg-gray-300 dark:bg-gray-800 rounded-lg" @click="openVideo">
      <p class="text-center h-10 self-center">Thumbnail not found</p> 
    </div>

    <div class="flex items-start mt-4 space-x-2">
      <div class="size-8 shrink-0" @click="openChannel">
        <UserAvatar :avatar="user?.avatar" />
      </div>
      <div class="text-black dark:text-white">
        <h3 class="text-sm line-clamp-1">{{ item.title }}</h3>
        <p class="text-gray-400 text-sm line-clamp-1">{{ user?.name }}</p>
        <p class="text-gray-400 text-sm line-clamp-1">{{ timeAgoMessage }} • {{ item.postEngagement.views }} views </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { IonIcon } from '@ionic/vue';
import { playSharp } from 'ionicons/icons';
import { useRouter } from 'vue-router';
import UserAvatar from '../UserAvatar.vue';
import { useUserStore } from '@/stores/users';
import { timeAgo } from '@/helpers/timeAgo';

const router = useRouter();

const props = defineProps({
  item: Object,
  size: Number,
});

let timeAgoMessage = ref(timeAgo(new Date(props.item.createdAt)));

const userStore = useUserStore();

const user = computed(() => {
  return userStore.users[props.item.user.userId];
});

const videoUrl = props.item?.videos[0]?.url;
const baseUrl = computed(() => {
  const url = new URL(videoUrl);
  return `${url.origin}/`;
});

    const openVideo = () => {
  router.push(`/video/${props.item.id}`);
};

const openChannel = () => {
  const encodedUsername = encodeURIComponent(userStore.users[props.item.user.userId].username);

  router.push(`/${encodedUsername}/channel`);
};

const thumbnailUrl = computed(() => {
  const relativePath = props.item?.videos[0]?.thumbnail?.url;
  return relativePath ? `${baseUrl.value}${relativePath}` : '';
});
</script>