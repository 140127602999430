<template>
  <div :key="`header-${id}`" @click.stop.prevent=" event => { navigateToPost(event)} ">
    <div v-if="isEmptyRepost" class="-mt-3 items-center mb-2 -ml-1 font-bold cursor-pointer select-none">
      <div v-if="!(repostUsername === 'parlerdiscover' && globalStore.currentFeed === 'influencers') || isCurrentUserEmployee" 
        class="flex justify-start space-x-1 px-1 items-center font-bold cursor-pointer select-none"
        >
      <ion-icon  :src="repostIcon" class="link align-middle text-lg" /> 
      <span>reposted by</span>
      <span class="link cursor-pointer">@{{ repostUsername }}</span>
      </div>
      
      <span class="absolute top-4 right-4">
        <PostMenuForOwner v-if="repostUserId === authStore.currentUser.ulid" :id="id" :user-id="userId"
          :repost-user-id="repostUserId" :post-type="postType" :repost-id="repostId" />
        <PostMenu v-else :id="id" :user-id="userId" :username="username" :repost-user-id="repostUserId"
          :post-type="postType" :parentId="parentId" :repostUsername="repostUsername" :repost-id="repostId" :groupId="groupId" />
      </span>
    </div>
    <!-- <p v-if="repostUsernames && repostUsernames.length > 0" style="font-size: 0.7rem">
      <ion-icon :icon="repeatSharp" class="mr-1" /><span v-if="repostUsernames.length > 1"
        >recently </span> reposted by
      <span
        class="text-primary cursor-pointer"
        v-for="(username, index) in repostUsernames.slice(
          0,
          additionalReposts === 1 ? 2 : 1
        )"
        :key="index"
        @click="navigateToProfile(username)"
      >
        @{{ username }}

        <span v-if="index < 1 && repostUsernames[1]"> & </span>
      </span>
      <span
        @click.stop="openRepostPopup($event)"
        :id="['moreReposts' + id]"
        class="text-primary cursor-pointer"
        v-if="additionalReposts > 1"
      >
        {{ additionalReposts }} More</span
      >
    </p>
    <ion-popover
      :is-open="repostPopup"
      @didDismiss="repostPopup = false"
      :event="additionReposts"
      v-if="repostUsernames && repostUsernames.length > 2"
    >
      <p
        v-for="repostUsername in repostUsernames.slice(1)"
        :key="repostUsername"
        @click="navigateToProfile(repostUsername)"
        class="text--primary p-1"
      >
        @{{ repostUsername }}
      </p>
    </ion-popover> -->
    <div ref="headerRef" class="flex items-center relative"
    :class="isGroupPost ? 'ml-6 mt-2' : ''">
      <div class="flex items-center w-[95%] cursor-pointer select-none" 
        >
        <div class="w-10 h-10 rounded-full aspect-1 shrink-0 relative" :class="{ 'w-9 h-9': isCommentDetails }" @click.stop.prevent="navigateToProfile(userStore.getUsername(userId))">
          <div  v-if="isGroupPost"
            class="absolute -top-4 -left-4 size-9" @click.stop.prevent="navigateToGroup">
            <GroupAvatar :id="groupId" :avatar="postStore.groups[groupId].avatar" />
          </div>
          
          <UserAvatar :id="userId" :avatar="userId === authStore.currentUser.ulid
                                        ? authStore.currentUser.avatar
                                        : userStore.getAvatar(userId)
                                        "
                                        :isGroupPost="isGroupPost"
                                        />
          </div>
        <div class="w-full ml-2" :class="{ 'ml-[5px]': isCommentDetails }">
          <div class="w-full flex justify-between">
            <div 
            @click.stop.prevent="navigateToProfile(userStore.getUsername(userId))" 
            class="font-bold flex items-center space-x-1 text-[1rem] sm:text-lg md:text-lg lg:text-xl"
            :class="isCommentDetails || containerWidth < 376 ? 'text-md sm:text-md md:text-md lg:text-lg' : ''">
            <span :class="[quoted || depth > 2 ? (depth > 5 ? 'max-w-[35vw]' : 'max-w-[45vw]') : 'max-w-[55vw]']" class="truncate text-black dark:text-white font-bold">
              {{
                userId === authStore.currentUser.ulid
                ? authStore.currentUser.name
                : userStore.getName(userId)
              }}
            </span>
            <ParlerBadges v-if="globalStore.isParler" :isCommentDetails="isCommentDetails" :userId="userId"
              :username="userStore.getUsername(userId)" />
            <BadgesTemplate v-else :isCommentDetails="isCommentDetails" :userId="userId"
              :username="userStore.getUsername(userId)" />
            <ion-icon :src="connectionsIcon" class="ml-1 text-lg text-primary" v-if="isFollowing && !globalStore.isParler" title="Connected!" />
          </div> 
        </div>
          <div class="flex items-center space-x-2 -mt-1 w-full" @click.stop.prevent="navigateToProfile(userStore.getUsername(userId))">
            <div class="flex items-center justify-start w-full space-x-1 text-[0.8rem] sm:text-lg md:text-lg lg:text-xl font-normal text-gray-500" v-if="globalStore.isParler">
            <span 
            :class="quoted || depth > 2 ? 'max-w-[45vw]' : 'max-w-[55vw]'" class="truncate">
              @{{ userStore.getUsername(userId) }}
            </span>
            <span 
             >•</span>
              <span>{{ depth > 2 ? commentTimeAgoMessage : timeAgoMessage }} </span>
              <span v-if="isDetail">•</span>
              <FormatCount v-if="isDetail"  :count="views" />
              <ion-icon v-if="isDetail" :src="viewsIcon" />
          
            </div>
            <div v-if="isCommentDetails && depth < 3 && !globalStore.isParler"
              class="font-normal text-gray-500 pl-1 whitespace-nowrap flex items-center">
              <ion-icon :icon="eyeSharp" class="pr-1 pb-px text-md align-middle" />
              <FormatCount :count="views" :isCommentDetails="isCommentDetails" class="text-[1rem] sm:text-lg md:text-lg lg:text-xl" />
            </div>
            <span v-if="postType === 'AD'"
              class="italic font-normal whitespace-nowrap">
              - Sponsored Ad</span>
          </div>
          <div class="w-full">
            <p class="text-md sm:text-md md:text-md lg:text-lg text-primary flex items-center m-0"
              :class="{ 'text-sm': isCommentDetails }">
              <span v-if="!isCommentDetails && !globalStore.isParler">
                @{{
                  userId === authStore.currentUser.ulid
                  ? authStore.currentUser.username
                  : userStore.getUsername(userId)
                }}</span>
              <span :class="!globalStore.isParler ? 'pl-2' : ''" class=" text-gray-500 whitespace-nowrap text-xs sm:text-sm md:text-md lg:text-lg lx:text-xl"
                v-if="postType != 'AD' && !isCommentDetails"></span>
            <div class="text-gray-500 pl-3 whitespace-nowrap flex items-center"
              v-if="(postType != 'AD' || userId === authStore.currentUser.ulid) && !isCommentDetails && !globalStore.isParler">
              <ion-icon :icon="eyeSharp" class="pr-1 text-lg align-middle" />
              <FormatCount :count="views" />
            </div>
            </p>
          </div>
        </div>
        <div v-if="!isEmptyRepost && !isCreatePost" class="absolute top-0" :class="[depth > 2 && containerWidth < 364 ? (depth > 5 && containerWidth < 364 ? 'right-6' : 'right-5') : 'right-0']">
              <PostMenuForOwner v-if="userId === authStore.currentUser.ulid" :id="id" :user-id="userId" :repost-user-id="repostUserId"
                :post-type="postType" :repost-id="repostId" :isDetail="isDetail" />
              <PostMenu v-else :id="id" :user-id="userId" :username="username" :repost-user-id="repostUserId"
                :post-type="postType" :groupId="groupId"
                />
            </div>
      </div>
    </div>
  </div>
</template>
<script setup>

import { repeatSharp, eyeSharp } from "ionicons/icons";
import connectionsIcon from "@/assets/connections.svg";
import { timeAgo, commentTimeAgo } from "@/helpers/timeAgo.js";
import { computed, ref, onMounted, onBeforeUnmount } from "vue";
import { useUserStore } from "@/stores/users.js";
import { useAuthStore } from "@/stores/auth.js";
import { useGlobalStore } from "@/stores/global.js";
import { usePostStore } from "@/stores/post.js"
import { IonIcon } from "@ionic/vue";
import { useRouter, useRoute } from "vue-router";
import PostMenuForOwner from "@/components/posts/PostMenuForOwner.vue";
import PostMenu from "@/components/posts/PostMenu.vue";
import BadgesTemplate from "@/components/BadgesTemplate.vue";
import ParlerBadges from "@/components/tenantcomponents/parler/ParlerBadges.vue";
import FormatCount from "@/components/FormatCount.vue";
import UserAvatar from "@/components/UserAvatar.vue";
import viewsIcon from "@/assets/parler/views.svg";
import repostIcon from "@/assets/parler/repost_icon.svg";
import GroupAvatar from "../groups/GroupAvatar.vue";

const props = defineProps({
  id: String,
  createdAt: String,
  userId: String,
  repostUserId: String,
  repostId: String,
  postType: String,
  views: Number,
  additionalReposts: Number,
  repostUsernames: Array,
  quoted: {
    type: Boolean,
    default: false,
  },
  isCommentDetails: {
    type: Boolean,
    default: false,
  },
  depth: {
    type: Number,
    default: 0,
  },
  isEmptyRepost: {
    type: Boolean,
    default: false,
  },
  isDetail: {
    type: Boolean,
    default: false,
  },
  feedType: {
    type: String,
  },
  isCreatePost: {
  type: Boolean,
  default: false,
  },
  parentId: String,
  groupId: String,
});

const router = useRouter();
const route = useRoute();
const userStore = useUserStore();
const postStore = usePostStore();
const authStore = useAuthStore();
const globalStore = useGlobalStore();
const testCount = ref(999);
const username = computed(() => userStore.getUsername(props.userId));
const repostUsername = computed(() => userStore.getUsername(props.repostUserId));
const user = computed(() => userStore.getUser(props.userId));
const headerRef = ref(null);
const containerWidth = ref(375);

const isCurrentUserEmployee = computed(() => {
  return (
    authStore.currentUser.badges &&
    authStore.currentUser.badges.includes("employee")
  );
});

const isGroupPost = computed(() => {
  return Boolean(props.groupId !== '01gssz7xsrqzth07cstd90t179' && postStore.groups[props.groupId] && route.name !== 'Group');
});

const repostPopup = ref(false);

const isFollowing = computed(() => {
  return Boolean(props.isCommentDetails && user.value?.ProfileEngagement?.isFollowingYou && user.value?.ProfileEngagement?.isFollowing);
});

let timeAgoMessage = ref(timeAgo(new Date(props.createdAt)));

let commentTimeAgoMessage = ref(commentTimeAgo(new Date(props.createdAt)));

let additionReposts = null;

const navigateToProfile = (fnUsername) => {
  if (fnUsername === 'Loading...') {
    if (fnUsername === username.value) {

      userStore.fetchUsersByIds([props.userId]);
    } else if (fnUsername === repostUsername.value) {
      userStore.fetchUsersByIds([props.repostUserId]);
    }
    return;
  }

  const encodedUsername = encodeURIComponent(fnUsername);

  router.push(`/${encodedUsername}`);
};

const navigateToPost = (event) => {
  !event?.target.id.startsWith('popover-up-menu') && router.push(`/post/${props.id}`);
};

const navigateToGroup = () => {
  router.push(`/group/${props.groupId}`);
};

// Update timeAgo message every second
let intervalId;
onMounted(() => {
  intervalId = setInterval(() => {
    timeAgoMessage.value = timeAgo(new Date(props.createdAt));
  }, 1000);

  // Set the container width
  if (headerRef){
    containerWidth.value = headerRef.value.offsetWidth;
  }

  if (props.groupId && props.groupId !== '01gssz7xsrqzth07cstd90t179' && !postStore.groups[props.groupId]) {
    postStore.fetchGroups([props.groupId]);
  }
});

onBeforeUnmount(() => {

  if (intervalId) {
    clearInterval(intervalId);
  }

});
</script>
<style scoped>
.link {
  color: var(--tertiary-color);
  /* Additional styling as needed */
}

.card-content-ios p {
  /* Reset font size and line-height */
  font-size: 0.75rem;
  line-height: inherit;
}

.text-primary {
  color: var(--primary-color);
}
</style>
