import axios from "axios"
import { useAuthStore } from "./stores/auth"
import { useGlobalStore } from "./stores/global"
import { sendToAnalytics, sendErrorToAnalytics } from "@/helpers/analyticsHelper"
import router from "@/router";
// get token outside component to avoid re-login

const appendAccessToken = async config => {
    const authStore = useAuthStore()

    if (authStore.accessToken) {
        config.headers.Authorization = `Bearer ${authStore.accessToken}`
    }
    startStopSpinner("block")
    return config
}

const apiBackendAuthAxios = axios.create({
    baseURL: import.meta.env.VITE_API_HOST + "/v3"
})
apiBackendAuthAxios.interceptors.request.use(appendAccessToken, error => {
    startStopSpinner()
    Promise.reject(error)
})

function startStopSpinner(display = "none") {
    const spinner = document.getElementById("spinner")
    if (spinner) spinner.style.display = display
}

let countOfErrors = 0;
let countOfSuccess = 0;

apiBackendAuthAxios.interceptors.response.use(
    function (response) {
        startStopSpinner();
        const globalStore = useGlobalStore();
        countOfSuccess++;
        countOfErrors = 0;
        if (countOfSuccess > 3 && globalStore.isBackendDown) {

            globalStore.setBackendDown(false);
            countOfSuccess = 0;
        }
        // Send successful API requests to analytics
        sendToAnalytics({
            method: response.config.method,
            url: response.config.url,
        });
        return response;
    },
    function (error) {
        const globalStore = useGlobalStore();

        if (error.response && error.response.status >= 500) {
            countOfErrors++;
            countOfSuccess = 0;
            if (countOfErrors > 5 && !globalStore.isBackendDown) {
                globalStore.setBackendDown(true);
            }
        }

        startStopSpinner();
        return Promise.reject(error)
    }
)

const apiBackendAxios = axios.create({
    baseURL: import.meta.env.VITE_API_HOST
})
apiBackendAxios.interceptors.request.use(
    config => {
        startStopSpinner("block");
        return config
    },
    error => {
        startStopSpinner();
        Promise.reject(error)
    }
)
apiBackendAxios.interceptors.response.use(
    function (response) {
        startStopSpinner()
        return response
    },
    function (error) {
        startStopSpinner()
        return Promise.reject(error)
    }
)

// Threshold for consecutive 401 responses
const THRESHOLD = 20;

// Timeframe (in milliseconds) for consecutive 401 responses
const TIMEFRAME = 60000; // 60 seconds

let consecutive401Count = 0;
let timestampQueue = [];

apiBackendAuthAxios.interceptors.response.use(
    function (response) {
        // Reset the consecutive 401 counter on successful responses
        consecutive401Count = 0;
        timestampQueue = []; // Clear the timestamp queue
        startStopSpinner();

        return response;
    },
    async function (error) {

        const originalConfig = error.config;
        if (error.response && error.response.status === 401) {
            originalConfig._retry = true;

            // Push the current timestamp to the queue
            const currentTimestamp = Date.now();
            timestampQueue.push(currentTimestamp);

            // Remove timestamps that are older than the specified timeframe
            const currentTime = Date.now();
            while (timestampQueue.length > 0 && currentTime - timestampQueue[0] > TIMEFRAME) {
                timestampQueue.shift();
            }

            // Update the consecutive 401 count based on the length of the timestamp queue
            consecutive401Count = timestampQueue.length;

            if (consecutive401Count > THRESHOLD) {
                const authStore = useAuthStore();

                authStore.clearUserData();
                authStore.setUnauthenticated(router);
            } else {
                try {
                    const authStore = useAuthStore();

                    const rs = await apiBackendAxios.post("/auth/refreshtoken", {
                        refresh_token: authStore.refreshToken,
                    });

                    const tokenData = rs.data;
                    await authStore.setAuthenticated(tokenData);
                    await authStore.setUser();

                    return apiBackendAuthAxios(originalConfig);
                } catch (_error) {
                    return Promise.reject(_error);
                }
            }
        } else {
            // Reset the consecutive 401 counter and timestamp queue for non-401 responses
            consecutive401Count = 0;
            timestampQueue = [];
        }

        startStopSpinner();
        return Promise.reject(error);
    }
);

export { apiBackendAuthAxios, apiBackendAxios }
