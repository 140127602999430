<template>
  <div class="relative" :class="isGroupPost ? 'size-7' : 'h-full w-full'">
    <div
      class="flex justify-center items-center w-full h-full rounded-full overflow-hidden relative bg-gray-200 dark:bg-gray-800"
    >
      <ion-img
        v-show="avatar && (!avatarLoading || !avatarError)"
        :src="avatar + `?width=${imageWidth}`"
        :class="avatarError || avatarLoading ? 'opacity-0' : ''"
        class="w-full h-full cursor-pointer select-none object-cover"
        @ionImgWillLoad="toggleAvatarLoading"
        @ionError="toggleAvatarError"
      />

      <div
        v-if="avatarLoading || avatarError"
        class="absolute inset-0 w-full h-full object-cover flex justify-center items-center"
      >
        <ion-icon
          :icon="personSharp"
          class="text-gray-400 dark:text-gray-600"
          :class="[avatarLoading ? 'animate-pulse' : '', small ? 'text-md' : 'text-2xl']"
        />
      </div>
    </div>
    <div v-if="id && avatar" class="absolute bottom-0 right-0">
      <LastActive :id="id" />
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import { personSharp } from "ionicons/icons";
import { IonImg, IonIcon } from "@ionic/vue";
import LastActive from "./LastActive.vue";

const props = defineProps({
  avatar: {
    type: String,
    required: false,
  },
  id: {
    type: String,
    required: false,
  },
  small: {
    type: Boolean,
    default: false,
  },
  isGroupPost: {
    type: Boolean,
    default: false,
  },
});

const avatarLoading = ref(true);
const avatarError = ref(false);

const imageWidth = 40;

const toggleAvatarLoading = () => {
  avatarLoading.value = false;
};

const toggleAvatarError = () => {
  avatarError.value = true;
};
</script>
<style scoped></style>
