<template>
  <section>
    <input
      ref="mediaUploader"
      type="file"
      class="hidden"
      :multiple="allowMultiple"
      :accept="'video/quicktime,video/mp4,image/png,image/gif,image/jpeg,image/webp'"
      @change="processUpload"
      :disabled="remainingSlots <= 0"
    />
    <input type="hidden" name="file" />
    <div v-if="uploadStarted" class="pt-2">
      <ion-progress-bar :value="uploadProgress"></ion-progress-bar>
      <p class="text-tertiary">
        <ion-spinner class="align-middle" />Upload Progress: {{ uploadProgress }}%
      </p>
    </div>
  </section>
</template>

<script setup>
import { ref, watch, onBeforeUnmount } from "vue";
import { useAuthStore } from "@/stores/auth";
import { apiBackendAuthAxios } from "@/axiosAuth";
import { IonProgressBar, IonSpinner } from "@ionic/vue";

const triggers = defineEmits(["changedMedias", "uploadStarted", "uploadFinished"]);
const mediaUploader = ref(null);
const uploadStarted = ref(false);
const uploadProgress = ref(0);

const maxMedia = 5;

const props = defineProps({
  showPreview: {
    type: Boolean,
    default: true,
  },

  alreadyUploaded: {
    type: Number,
    default: 0,
  },

  allowMultiple: {
    type: Boolean,
    default: false,
  },
});

const remainingSlots = ref(maxMedia - props.alreadyUploaded);

watch(uploadStarted, (newValue) => {
  if (newValue === true) {
    triggers("uploadStarted");
  } else {
    triggers("uploadFinished");
  }
});

function uploadExternalFile(file) {
  if (validateFile(file)) {
    uploadImageFile(file);
  }
}

function start() {
  if (remainingSlots.value > 0) {
    mediaUploader.value.click();
  } else {
    alert("Maximum upload limit reached.");
  }
}

function validateFile(file) {
  // const fileSizeInMB = file.size / (1024 * 1024); TODO need file size limits.
  const validFileTypes = [
    "video/quicktime",
    "video/mp4",
    "image/png",
    "image/gif",
    "image/jpeg",
    "image/webp",
  ];

  // if (fileSizeInMB > 50) {
  //   // Assuming 50 MB is the maximum size
  //   alert("File size should not be more than 50 MB");
  //   return false;
  // }

  if (!validFileTypes.includes(file.type)) {
    alert("Invalid file format");
    return false;
  }

  return true;
}

const videoExtensions = [
  "mp4",
  "mov",
  "avi",
  "wmv",
  "flv",
  "mkv",
  "webm",
  "hevc",
  "heic",
  "heif",
];

const fileExtension = ref(null);

function processUpload(event) {
  Array.from(event.target.files).forEach((file) => {
    if (validateFile(file)) {
      // Extract the file extension from the original name

      fileExtension.value = file.name.split(".").pop().toLowerCase();

      // Remove spaces and parentheses from the file name
      const sanitizedFileName = file.name.replace(/[()\s]/g, "_");

      // Construct the sanitized File object
      const sanitizedFile = new File([file], sanitizedFileName, { type: file.type });

      // Upload the sanitized file
      uploadImageFile(sanitizedFile);

    }
  });

  event.target.value = null;
}

const authStore = useAuthStore();

const uploadUrl = import.meta.env.VITE_API_HOST + "/v3/media/signed-storage-url";

async function uploadImageFile(file) {
  uploadStarted.value = true;

  // Check if the file is an image and extract its dimensions

  try {
    let preview;

    // Append dimensions to the preview object for images
    if (videoExtensions.includes(fileExtension.value)) {
      const dimensions = await getVideoDimensions(file);
      preview = {
        url: "", // Placeholder, will be replaced after upload
        isVideo: true,
        ...dimensions, // Spread dimensions into the preview object
      };
    } else {
      const dimensions = await getImageDimensions(file);

      preview = {
        url: "", // Placeholder, will be replaced after upload
        isVideo: false,
        ...dimensions, // Spread dimensions into the preview object
      };
    }

    // Continue with the upload, and ensure the URL is set afterwards
    // Note: The rest of your upload logic remains the same

    window.Vapor.store(file, {
      signedStorageUrl: uploadUrl,
      visibility: "public-read",
      headers: {
        Authorization: `Bearer ${authStore.accessToken}`,
      },
      progress: (progress) => {
        uploadProgress.value = Math.round(progress * 100);
      },
    }).then((response) => {
      const encodedFileName = encodeURIComponent(file.name);
      apiBackendAuthAxios
        .post("/media/upload", {
          uuid: response.uuid,
          key: response.key,
          bucket: response.bucket,
          name: encodedFileName,
          content_type: file.type,
        })
        .then((uploadResponse) => {
          if (remainingSlots.value > 0) {
            uploadProgress.value = 0;
            preview.url = uploadResponse.data.url; // Set the actual URL

            triggers("changedMedias", preview);
            remainingSlots.value -= 1;
          } else {
            console.error("Upload limit reached");
          }
          uploadStarted.value = false;
        })
        .catch((error) => {
          console.error(error);
          uploadStarted.value = false;
        });
    });
  } catch (error) {
    console.error("Error uploading media", error, file);
  }
}

// Utility function to get image dimensions
function getImageDimensions(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        resolve({ widthPx: img.width, heightPx: img.height });
      };
      img.onerror = reject;
      img.src = e.target.result;
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

// Utility function to get video dimensions
function getVideoDimensions(file) {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    video.preload = 'metadata';

    video.onloadedmetadata = function () {
      window.URL.revokeObjectURL(video.src);
      resolve({ widthPx: video.videoWidth, heightPx: video.videoHeight });
    };

    video.onerror = function () {
      reject('Error loading video metadata');
    };

    video.src = URL.createObjectURL(file);
  });
}

defineExpose({
  start,
  uploadExternalFile,
  uploadImageFile,
});

onBeforeUnmount(() => {
  uploadStarted.value = false;

  if (mediaUploader.value) {
    mediaUploader.value.remove();
  }
});

</script>

<style scoped>
.text-tertiary {
  color: var(--tertiary-color);
}
</style>
