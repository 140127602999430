<template>
  <div ref="containerRef" v-if="isIos ? iosVideoUrl : videoUrl" @click.stop class="relative overflow-hidden w-full flex rounded-lg justify-center select-none  max-h-[75vh]"
    :style="videoContainerStyle">
    <video 
      ref="videoRef"
      class="video-js vjs-parler vjs-show-big-play-button-on-pause rounded-md justify-self-center"
      playsinline
    ></video>
    
    <button v-if="showFakePlayButton" class="fake-play-button flex items-center justify-center" @click.stop.prevent="playVideo">
      <ion-icon :icon="playSharp"/>
    </button>
    <div v-else @click="toggleVideo" class="absolute top-0 left-0 right-0 bottom-12"></div>
  </div>
</template>
<script setup>
import { ref, onMounted, onBeforeUnmount, watch, computed } from 'vue';
import { IonIcon } from '@ionic/vue';
import { playSharp } from 'ionicons/icons';
import { useGlobalStore } from '@/stores/global';
import { usePostStore } from '@/stores/post';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

const props = defineProps({
  videoUrl: {
    type: String,
  },
  thumbnailUrl: {
    type: String,
  },
  mimeType: {
    type: String,
    default: "video/mp4",
  },
  postId: {
    type: String,
  },
  height: {
    type: Number,
    default: 0,
  },
  width: {
    type: Number,
    default: 0,
  },
  singleVideo: {
    type: Boolean,
    default: true,
  },
  isProcessing: {
    type: Boolean,
    default: false,
  },
  isPlayTv: {
    type: Boolean,
    default: false,
  },
});

let player = null;
const videoRef = ref(null);
const showFakePlayButton = ref(true);
const containerRef = ref(null);
const global = useGlobalStore();
const postStore = usePostStore();

const isIos = computed(() => {
  const isPWA = Boolean(window.webkit && window.webkit.messageHandlers);
  const isSafari = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  return isPWA || isSafari;
});

const iosVideoUrl = computed(() => {
  return props.videoUrl ? props.videoUrl + '#t=0.001' : '';
});

const videoContainerStyle = computed(() => {

  return props.width < props.height && props.singleVideo && !props.isPlayTv
    ? { height: props.height + 'px', width: props.width + 'px' } 
    : {};
});

watch(
  () => global.playingVideoId,
  (newVal) => {
    if (newVal !== props.postId && player) {
      player.pause();
    }
  }
);

const playVideo = () => {

  if (player) {
    player.play();
    player.controls(true);
  }
};

const toggleVideo = () => {
  if (player) {
    if (player.paused()) {
      player.play();
    } else {
      player.pause();
    }
  }
};

const aspectRatio = computed(() => {
  return props.width && props.height && !props.isPlayTv ? `${props.width}:${props.height}` : '16:9';
});

let containerObserver;

const onVisibilityChange = (entries) => {
  entries.forEach((entry) => {
    if (!entry.isIntersecting && player && !player.paused() ) {
      player.pause();
    }
  });
};

const updatePlayerSource = () => {
  if (player && props.videoUrl) {
    player.src({
      src: isIos.value ? iosVideoUrl.value : props.videoUrl,
      type: props.mimeType,
    });
  }
};

watch(
  () => props.videoUrl,
  (newUrl, oldUrl) => {
    if (newUrl !== oldUrl) {
      updatePlayerSource();
    }
  }
);

onMounted(() => {

  if (videoRef.value) {
    const cleanedMimeType = props.mimeType.replace(/\\/g, '');

    const playerOptions = {
    controls: true,
    loop: true,
    muted: false,
    preload: 'auto',
    aspectRatio: aspectRatio.value, // Set dynamic aspect ratio
    playbackRates: [0.5, 1, 1.5, 2], // Add playback rates
    controlBar: {
      children: [
        'playToggle',
        'progressControl',
        'currentTimeDisplay',
        'timeDivider',
        'durationDisplay',
        'playbackRateMenuButton',
        'volumePanel',
        'fullscreenToggle',
        'castButton',
      ]
    },
    duration: true,
    breakpoints: {
      tiny: 210,
      xsmall: 320,
      small: 425,
      medium: 768,
      large: 1440,
      xlarge: 2560,
      huge: Infinity
    },
    sources: [
      {
        src: props.videoUrl,
        type: cleanedMimeType,
      },
    ],
  };

  if (props.thumbnailUrl) {
    playerOptions.poster = props.thumbnailUrl;
  }

  player = videojs(videoRef.value, playerOptions);


    player.addClass('vjs-parler' ); // Add custom class

    // remove fluid if video is portrait
    if (props.width < props.height) {
      player.removeClass('vjs-fluid');
      player.fill(true);
    }

    player.userActive(true);
    player.on('userinactive', () => {
      player.userActive(true);
    });

    // Set up the play event listener
    player.on('play', () => {
      showFakePlayButton.value = false;
      global.playingVideoId = props.postId;
    });

    // Add unique IDs based on postId
    const uniqueCurrentTimeId = `vjs-current-time-${props.postId}`;
    const uniqueDurationId = `vjs-duration-${props.postId}`;
    const uniqueTimeDividerId = `vjs-time-divider-${props.postId}`;

    player.controlBar.currentTimeDisplay.el().id = uniqueCurrentTimeId;
    player.controlBar.durationDisplay.el().id = uniqueDurationId;
    player.controlBar.timeDivider.el().id = uniqueTimeDividerId;

    player.on('loadedmetadata', () => {
      document.getElementById(uniqueCurrentTimeId).style.display = 'inline';
      document.getElementById(uniqueDurationId).style.display = 'inline';
      document.getElementById(uniqueTimeDividerId).style.display = 'inline';
    });

    player.on('error', () => {
      if (props.isProcessing) {
        const errorDisplay = player.errorDisplay;
        errorDisplay.open();
        errorDisplay.contentEl().innerHTML = '<p>Your video is processing</p>';
      } else {
        refetchPost();
      }
    });

    containerObserver = new IntersectionObserver(onVisibilityChange, {
      root: null, // relative to the viewport
      rootMargin: "0px",
      threshold: 0.1, // 10% of the item's visible area is enough to consider it as "not in view"
    });

    // Observe the target element
    if (containerRef.value) {
      containerObserver.observe(containerRef.value);
    }

    // Clean up the player and observer when the component unmounts
    onBeforeUnmount(() => {
      if (player) {
        player.dispose();
      }
      if (containerObserver) {
        containerObserver.disconnect();
      }
    });
  }
});

async function refetchPost() {
  // Refetch the post
  await postStore.fetchPostsByIds([props.postId], true);
  // Update the player source
  updatePlayerSource();
}

onBeforeUnmount(() => {
  if (player) {
    player.dispose();
  }
});

</script>

<style>
@import 'video.js/dist/video-js.css';

/* Ensure the video element respects the max height */
.vjs-parler {
  max-height: 75vh !important;
}

.fake-play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px;
  width: 80px;
  height: 80px;
  background: none;
  line-height: 80px;
  font-size: 55px;
  border-radius: 100%;
  padding-left: 10px;
  border: none;
  color: white;
  background-color: black;
  border: 2px solid white;
  transition: background-color 0.3s, transform 0.3s;
  cursor: pointer;
  z-index: 3;
}

.fake-play-button:hover,
.fake-play-button:focus {
  background-color: var(--primary-color);
}

.vjs-parler {
  --vjs-parler--primary: var(--primary-color);
  --vjs-parler--secondary: #fff;
}

/* Custom CSS for Video.js to resemble YouTube */
.vjs-parler .vjs-big-play-button {
  width: 80px;
  height: 80px;
  background: none;
  line-height: 77px;
  font-size: 70px;
  border-radius: 100%;
  padding-bottom: 25px;
  top: 50%;
  left: 50%;
  margin-top: -40px; /* Adjusted to half the height */
  margin-left: -40px; /* Adjusted to half the width */
  color: white;
  background-color: black;
  border: 2px solid white;
  transition: background-color 0.3s, transform 0.3s;
}

.vjs-parler:hover .vjs-big-play-button,
.vjs-parler .vjs-big-play-button:focus {
  background-color: var(--primary-color);
  transform: scale(1.1);
}

.vjs-parler .vjs-control-bar {
  height: 4em;
  background-color: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-shadow: #000 0 0.5px 0.1px;
  box-shadow: 0 -10px 100px 60px rgba(0, 0, 0, 0.25); /* Add bottom inset shadow */
}

.vjs-parler .vjs-control-bar .vjs-control .vjs-icon-placeholder::before {
  text-shadow: #000 0 0.5px 0.1px;
}

.vjs-parler .vjs-progress-control {
  width: 100%;
  height: 5px;
  position: absolute;
  top: 0;
  left: 0;
}

.vjs-parler .vjs-progress-control .vjs-progress-holder {
  height: 100%;
  margin: 0;
}

.vjs-parler .vjs-play-progress {
  background-color: var(--vjs-parler--primary);
}

.vjs-parler .vjs-play-control {
  font-size: 1.5em;
  position: relative;
  order: 0;
}

.vjs-parler .vjs-current-time,
.vjs-parler .vjs-duration,
.vjs-parler .vjs-time-divider {
  font-size: 1.5em; /* Adjust size as needed */
  line-height: 35px; /* Ensure they align properly */
  margin-left: -25px;
  order: 1;

}

.vjs-parler .vjs-time-divider {
  margin-top: 0.5em;
}

.vjs-parler .vjs-playback-rate {
  order: 4;
  margin-top: 1.5em;
}

.vjs-parler .vjs-volume-panel {
  order: 5;
  margin-left: auto; /* Move it to the right */
}

.vjs-parler .vjs-fullscreen-control {
  order: 6;
}

.vjs-parler .vjs-button > .vjs-icon-placeholder::before {
  line-height: 2.2;
}

.vjs-parler .vjs-time-control {
  line-height: 45px;
}

.vjs-parler .vjs-volume-bar {
  margin-top: 1.8em;
}

.vjs-parler .vjs-progress-control .vjs-progress-holder {
  font-size: 1.5em;
}

.vjs-parler .vjs-progress-control:hover .vjs-progress-holder {
  font-size: 1.5em;
}

.vjs-parler .vjs-play-control .vjs-icon-placeholder::before {
  height: 1.3em;
  width: 1.3em;
  margin-top: 0.2em;
  top: 2px;
  left: 9px;
  line-height: 1.1;
}

.vjs-parler .vjs-play-control:hover .vjs-icon-placeholder::before {
  color: var(--vjs-parler--primary);
}

.vjs-parler .vjs-volume-panel .vjs-volume-control {
  background: none;
  position: relative;
  width: 3em;
  height: 1.75em;
}

.vjs-parler .vjs-volume-panel .vjs-volume-bar {
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 3em;
  height: 1.75em;
  margin-top: 1.2em;
  overflow: hidden;
}

.vjs-parler .vjs-volume-panel .vjs-volume-bar::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 1.75em 3em;
  border-color: transparent transparent rgba(255, 255, 255, 0.25) transparent;
}

.vjs-parler .vjs-volume-level {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: transparent;
}

.vjs-parler .vjs-volume-level::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 1.75em 3em;
  border-color: transparent transparent var(--vjs-parler--secondary) transparent;
}

.vjs-parler .vjs-play-progress::before {
  height: 0.8em;
  width: 0.8em;
  content: '';
  background-color: var(--vjs-parler--primary);
  border: 4px solid var(--vjs-parler--secondary);
  border-radius: 0.8em;
  top: -0.25em;
}

/* Responsive adjustments */
.vjs-layout-tiny .vjs-parler .vjs-big-play-button,
.vjs-layout-tiny .fake-play-button {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 45px;
  margin-top: -25px;
  margin-left: -25px;
}

.vjs-layout-tiny .vjs-parler .vjs-control-bar {
  height: 2em;
}

.vjs-layout-tiny .vjs-parler .vjs-time-control {
  line-height: 2em;
}

.vjs-layout-tiny .vjs-parler .vjs-play-control .vjs-icon-placeholder::before {
  height: 0.8em;
  width: 0.8em;
  margin-top: 0.1em;
  top: 0.5px;
  left: 4px;
  line-height: 0.8;
}

.vjs-layout-tiny .vjs-parler .vjs-progress-control .vjs-progress-holder {
  font-size: 0.8em;
}

.vjs-layout-tiny .vjs-parler .vjs-fullscreen-control {
  font-size: 0.8em;
}

.vjs-layout-tiny .vjs-parler .vjs-volume-panel,
.vjs-layout-tiny .vjs-parler {
  display: none;
}
</style>
