<template>
  <!-- <CreatePost v-if="showCreatePost && (isCurrentUserInfluencer || globalStore.currentFeed !== 'influencers')"/> -->
  <div class="flex items-center justify-between border-b-2">
    <div class="flex-grow">
      <div
        class="cursor-pointer select-none flex text-lg md:text-lg lg:text-xl justify-between space-x-4 pl-6 pt-2 border-gray-500 text-black dark:text-white"
      >
        <!-- <p @click="emitFeedType(defaultFeed)" :class="{ 'active-tab': feedType === defaultFeed && isHome }" class="ml-6">
                Home
            </p> -->
        <p 
          @click="
            feedType === 'following' ? emit('scroll-top') : emitFeedType('following')
          "
          :class="{ 'active-tab': feedType === 'following' && isHome }"
          class="pb-2"
        >
          Following
        </p>
        <p
          @click="
            feedType === 'influencers' ? emit('scroll-top') : emitFeedType('influencers')
          "
          :class="{ 'active-tab': feedType === 'influencers' && isHome }"
          class="pb-2"
        >
          Discover
        </p>
                <p
          @click="
            feedType === 'all'
              ? emit('scroll-top')
              : emitFeedType('all')
          "
          :class="{ 'active-tab': feedType === 'all' && isHome }"
          class="pb-2"
        >
          Global
        </p>
        <p
          v-if="feedType === 'newUsers' || defaultFeed === 'newUsers'"
          @click="feedType === 'newUsers' ? emit('scroll-top') : emitFeedType('newUsers')"
          :class="{ 'active-tab': feedType === 'newUsers' && isHome }"
          class="pb-2 items-center flex"
        >
          <ion-icon :src="leaf" class="text-2xl" />
        </p>
        <p
          v-if="
            feedType === 'violations' &&
            authStore.currentUser.badges.includes('moderator')
          "
          @click="
            feedType === 'violations' ? emit('scroll-top') : emitFeedType('violations')
          "
          :class="{ 'active-tab': feedType === 'violations' && isHome }"
          class="pb-2 items-center flex"
        >
          <ion-icon :icon="moderationIcon" class="text-lg md:text-lg" />
        </p>
        <p
          v-if="
            feedType === 'followingMutual' || defaultFeed === 'followingMutual'"
          @click="
            feedType === 'followingMutual' ? emit('scroll-top') : emitFeedType('followingMutual')
          "
          :class="{ 'active-tab': feedType === 'followingMutual' && isHome }"
          class="pb-2 items-center flex"
        >
          <ion-icon :icon="heartSharp" class="text-lg md:text-lg" />
        </p>

      </div>
    </div>
    <ion-icon
      class="mx-3 text-xl md:text-2xl cursor-pointer select-none text-black dark:text-white"
      :icon="alertCircleOutline"
      @click.stop.prevent="openSelect"
    />
    <ion-toggle
      v-model="parler"
      class="mr-6"
      color="primary"
      @ionChange="toggleParler"
      />
    
  </div>
</template>

<script setup>
import { IonIcon, actionSheetController, IonToggle } from "@ionic/vue";
import { ref, watch, computed } from "vue";
import {
  home,
  globeOutline,
  peopleOutline,
  starOutline,
  personAddOutline,
  heartSharp,
  alertCircleOutline,
} from "ionicons/icons";
import moderationIcon from "@/assets/moderation_icon.svg";
import leaf from "@/assets/parler/leaf.svg";
import { useRoute, useRouter } from "vue-router";
import { useAuthStore } from "@/stores/auth";
import { useGlobalStore } from "@/stores/global";

const props = defineProps({
  showCreatePost: Boolean,
});

const parler = computed(() => globalStore.parler);

const toggleParler = (e) => {
  globalStore.parler = e.detail.checked;
};

const isCurrentUserInfluencer = computed(() => {
  return (
    authStore.currentUser.badges && authStore.currentUser.badges.includes("influencer")
  );
});
const isCurrentUserEmployee = computed(() => {
  return (
    authStore.currentUser.badges && authStore.currentUser.badges.includes("employee")
  );
});

const isModerator = computed(() => {
  return (
    authStore.currentUser.badges && authStore.currentUser.badges.includes("moderator")
  );
});

const authStore = useAuthStore();
const globalStore = useGlobalStore();
const route = useRoute();
const router = useRouter();
const isHome = computed(() => route.path === "/feed");
const defaultSelector = ref(null);
const hiddenSelector = ref(true);

const emit = defineEmits(["feedTypeSelected", "scroll-top"]);

const openSelect = async () => {
  const actionButtons = [
    {
      text: "Following",
      handler: () => handleSelection("following", "Following"),
      cssClass: "default-feed",
      icon: peopleOutline,
    },
    {
      text: "Friends",
      handler: () => handleSelection("followingMutual", "Friends"),
      icon: heartSharp,
    },
    {
      text: "Discover",
      handler: () => handleSelection("influencers", "Influencers"),
      icon: starOutline,
    },

    {
      text: "Welcome",
      handler: () => handleSelection("newUsers", "Welcome"),
      icon: leaf,
    },
    {
      text: "Cancel",
      role: "cancel",
    },
  ];

  // Conditionally add the "Global" option if the user is an employee
  if (isCurrentUserEmployee.value || isModerator.value) {
    const globalButton = {
      text: "Global",
      handler: () => emitFeedType("all"),
      icon: globeOutline,
    };

    // Insert the global button before the "Moderation" button
    actionButtons.splice(actionButtons.length - 1, 0, globalButton);
  }

  if (isModerator.value) {
    const moderationButton = {
      text: "Moderation",
      handler: () => emitFeedType("violations"),
      icon: moderationIcon,
    };

    // Insert the moderation button before the "Cancel" button
    actionButtons.splice(actionButtons.length - 1, 0, moderationButton);
  }

  const actionSheet = await actionSheetController.create({
    header: "Select Your Default Feed",
    buttons: actionButtons,
    cssClass: "text-black dark:text-white",
  });

  await actionSheet.present();
};

const handleSelection = (value, text) => {
  // Update the selected feed
  globalStore.updateSettings("default_feed", value);

  emitFeedType(value);
};

const defaultFeed = computed({
  get: () => globalStore.settings.default_feed,
});

const defaultFeedName = computed(() => {
  switch (defaultFeed.value) {
    case "all":
      return "Global";
    case "following":
      return "Following";
    case "followingMutual":
      return "Friends";
    case "influencers":
      return "Discover";
    case "newUsers":
      return "Welcome";
    case "violations":
      return "Moderation";
    default:
      return "Pick one";
  }
});

const defaultIcon = computed(() => {
  switch (defaultFeed.value) {
    case "followingMutual":
      return heartSharp;
    case "following":
      return peopleOutline;
    case "all":
      return globeOutline;
    case "influencers":
      return starOutline;
    case "newUsers":
      return personAddOutline;
    default:
      return null;
  }
});

const feedType = computed(() => {
  return globalStore.currentFeed;
});

const emitFeedType = (selectedFeedType) => {
  if (route.path !== "/feed") {
    router.push("/feed");
  }

  emit("feedTypeSelected", selectedFeedType);

  globalStore.currentFeed = selectedFeedType;
};

watch(
  () => route.query.feedType,
  (newFeedType) => {
    feedType.value = newFeedType || "";
  }
);
</script>
<style scoped>
.message-box {
  position: absolute;
  top: 60%;
  transform: translateY(15%);
  white-space: normal;
  color: var(--tertiary-color);
}

.active-tab {
  position: relative;
  /* Needed to position the pseudo-element */
  color: var(--primary-color);
}

.active-tab::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -2px;
  /* Adjust this value to align with the div's bottom border */
  width: 100%;
  /* Or the width you want for your active indicator */
  height: 3px;
  /* Height of the active indicator */
  background-color: var(--primary-color);
  z-index: 10;
  /* Ensure it's above the bottom border of the container */
}

.text-primary {
  color: var(--primary-color);
  border-bottom: 3px solid var(--primary-color);
}

:deep(.default-feed) {
  background-color: red !important;
  color: red !important;
}

ion-action-sheet.default-feed {
  --background: #f58840;
  --backdrop-opacity: 0.6;
  --button-background-selected: #e97223;
  --button-color: #000000;
  --color: #fff;
  /* role: "destructive" button iOS styling override */
  --ion-color-danger: #000000;
}

/* Add any additional styling for the message as needed */
</style>
