<template>
    <ion-modal :is-open="isOpened" :initial-breakpoint="0.75" :breakpoints="[0, 0.25, 0.5, 0.75, 1]"
        @willDismiss="closeInviteModal">
        <ion-header>
            <ion-toolbar>
                <ion-title>Invite via Email</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
            <form @submit.prevent="login" class="px-8 pt-6 pb-8 mb-4">
                <div class="mb-4">
                    <label for="email" class="block font-bold mb-2">Email</label>
                    <input type="email" id="email" v-model="email" required
                        class="appearance-none border rounded w-full py-2 px-3 text-gray-700 bg-white leading-tight focus:outline-none focus:shadow-outline" />
                </div>
                <div class="mb-6 flex flex-col">
                    <div v-if="inviteError || inviteSuccess" class="rounded-lg text-black text-md mb-2" :class="{
                        'bg-green-200': inviteSuccess,
                        'bg-red-200': inviteError,
                    }">
                        <p class="p-2 text-center">{{ inviteMessage }}</p>
                    </div>
                    <div class="button-group cursor-pointer select-none">
                        <ion-button type="submit" @click="invite">
                            <ion-icon :icon="mailSharp" />
                        </ion-button>
                        <ion-button color="medium" @click="closeInviteModal"> Cancel </ion-button>
                    </div>
                </div>
            </form>
        </ion-content>
    </ion-modal>
</template>

<script setup>
import {
    IonTitle,
    IonModal,
    IonContent,
    IonHeader,
    IonToolbar,
    IonButton,
    IonIcon,
} from "@ionic/vue";
import { mailSharp } from "ionicons/icons";
import { ref } from "vue";
import { apiBackendAuthAxios } from "@/axiosAuth";

defineProps({
    isOpened: {
        type: Boolean,
        required: true,
    },
});

const emit = defineEmits(["closeInvite", "showToash"]);
const email = ref("");
const inviteError = ref(false);
const inviteSuccess = ref(false);
const inviteMessage = ref(false);

function closeInviteModal() {
    email.value = "";
    inviteMessage.value = "";
    emit("closeInvite");
}

async function invite() {
    // Regular expression for email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    try {
        if (!email.value || !emailRegex.test(email.value)) {
            return; // If email is not provided or doesn't match the format exit the function early
        }
        const response = await apiBackendAuthAxios.post("/user/inviteByEmail", {
            email: email.value,
        });

        if (response.status === 200 || response.status === 202) {
            // API request was successful
            inviteSuccess.value = true;
            inviteMessage.value =
                "Invitation sent successfully! Would you like to invite another person?";
            emit("showToash", {
                message: "Invitation sent successfully!",
                type: "success",
            });

            email.value = "";
        } else {
            // Handle the case where the response is not successful
            inviteError.value = true;
            inviteMessage.value = "Uh oh, something went wrong, try again";
            emit("showToash", {
                message: "Uh oh, something went wrong, try again",
                type: "error",
            });
        }
    } catch (error) {
    handleInviteError(error);
  }
}

function handleInviteError(error) {
  if (error.response && error.response.status === 400 && error.response.data.message === "User already invited") {
    inviteError.value = true;
    inviteMessage.value = error.response.data.message;
    emit("showToash", {
      message: error.response.data.message,
      type: "error",
    });
  } else if (error.response && error.response.status === 403) {
    inviteError.value = true;
    inviteMessage.value = error.response.data.error ? error.response.data.error : "There was an error sending the invite, please try again.";
    emit("showToash", {
      message: error.response.data.error ? error.response.data.error : "There was an error sending the invite, please try again.",
      type: "error",
    });
  } else {
    // General error handling
    inviteError.value = true;
    inviteMessage.value = "An unexpected error occurred. Please try again later.";
    emit("showToash", {
      message: "An unexpected error occurred. Please try again later.",
      type: "error",
    });
  }
}
</script>
