// postStore.js

import { defineStore } from 'pinia';
import { apiBackendAuthAxios } from '@/axiosAuth.js';
import { useUserStore } from '@/stores/users.js';
import { useAuthStore } from '@/stores/auth.js';
import { useGlobalStore } from '@/stores/global.js';
import { randomInsert } from '@/helpers/arrayHelper.js';
import axios from 'axios';
import Dexie from 'dexie';

export const usePostStore = defineStore({
  id: 'postStore',
  state: () => ({
    postsCache: {},
    groups: {},
    violationsCache: {},
    visiblePosts: [],
    postEngagementQueue: [],
    newPosts: {
      all: [],
      following: [],
      followingMutual: [],
      newUsers: [],
    },
    feedMaps: {
      all: [],
      following: [],
      xposts: [],
      followingMutual: [],
      profile: [],
      search: [],
      newUsers: [],
      comments: [],
      trending: [],
      influencers: [],
      videos: [],
    },
    nextCursor: {
      comments: {},
    },
    groupList: {
      myGroups: [],
      trending: [],
      pinned: [],
      new: [],
    },
    xpostsIndex: 0,
    sliceFeed: 50,
    hasMoreItems: true,
    page: 1,
    cursor: null,
    parentPosts: {},
    grandparentPosts: {},
    rootPosts: {},
    searchedUserUlid: null,
    cancelTokenSource: null,
    refreshFeedMapsOnNextFetch: false,
    trendingHashtags: [],
    fetchTrending: true,
    numberofTrendingHashtagFetches: 0,
  }),
  actions: {

    getPostDb() {
      const authStore = useAuthStore();
      const userId = authStore.currentUser?.ulid;
      if (userId) {
        try {
          const postDb = new Dexie(`PostDatabase_${userId}`);
          postDb.version(1).stores({
            posts: 'id,user,parentUlid,rootUlid,groupId,postType,title,body,detectedLanguage,createdAt,updatedAt,embedUrl,isDeleted,isProcessing,isRepost,isRepostWithComment,images,postEngagement,userEngagement,otherRepostUsers,cachedAt',
            metadata: 'key,value',
          });
          return postDb;
        }
        catch (error) {
          console.error(`Failed to open IndexedDB: ${error}`);
          throw error;
        }
      }
    },

    deletePostDb() {
      const authStore = useAuthStore();
      const userId = authStore.currentUser?.ulid;
      if (userId) {
        Dexie.delete(`PostDatabase_${userId}`).then(() => {

        }).catch((error) => {
          console.error(`Failed to delete IndexedDB: ${error}`);
        });
      } else {
        console.error("User ID is undefined or null");
      }
    },

    // Action to strip post down to skeletons
    stripPostToSkeleton(postId) {
        const post = this.postsCache[postId];
        if (post && !post.isSkeleton && !(post.postType === "COMMENT" || post.postType === "REPLY")) { // Check if post is not already a skeleton
          const skeleton = {
            body: post.body !== '' ? "..." : null, // Placeholder body
            embedUrl: post.embedUrl !== '' ? "placeholder" : null, // Placeholder embed URL
            id: post.id,
            images: post.images,
            isSkeleton: true // Mark as skeleton
            // Keep other essential properties
          };
          this.postsCache[postId] = skeleton;
        }
    
    },

    // Action to restore post from skeletons
    async restorePostFromSkeleton(postId) {
      let fullPost = null;
      try {
      // Fetch posts from IndexedDB
      fullPost = await this.getPostFromIndexDB(postId);
      if (fullPost) {

        this.postsCache[fullPost.id] = { ...fullPost, isSkeleton: false };

      // Handle post engagements
      this.postEngagementQueue = [...new Set([...this.postEngagementQueue, ...postId])];
      if (this.postEngagementQueue.length > 19) {
        this.fetchPostEngagements(this.postEngagementQueue);
        this.postEngagementQueue = [];
      }
      } else {
        fullPost = await this.fetchPostsByIds([postId]);
      }

      return fullPost;

    } catch (error) {
      console.error(`Failed to restore post from skeleton: ${error}`);
    } 
  },

    async getPostFromIndexDB(postId) {
      const postDb = this.getPostDb();
      if (!postDb) return null;  // Early exit if the DB isn't available

      try {
        const post = await postDb.table('posts').get(postId);
        return post || null;  // Return the post if found, or null if not
      } catch (error) {
        console.error(`Failed to get post from IndexedDB: ${error}`);
        return null;  // Return null to handle errors gracefully
      }
    },

    async updatePostInDB(post) {
  
      if (post.isSkeleton) {
        return;
      }
      try {

        const postDb = this.getPostDb();
        const clonedPost = JSON.parse(JSON.stringify(post)); // This strips functions and other non-serializable values
        clonedPost.cachedAt = new Date().toISOString();

        await postDb.table('posts').put(clonedPost);

      } catch (error) {
        console.error(`Failed to update posts from IndexedDB: ${error}`);
        throw error;
      }
    },

    async deletePostInDB(postId) {
      try {
      const postDb = this.getPostDb();
      await postDb.table('posts').delete(postId);
      } catch (error) {
      console.error(`Failed to delete post from IndexedDB: ${error}`);
      throw error;
      }
    },

    async deletePostsInDB(postIds) {
      try {
      const postDb = this.getPostDb();
      await postDb.table('posts').bulkDelete(postIds);
      } catch (error) {
      console.error(`Failed to delete posts from IndexedDB: ${error}`);
      throw error;
      }
    },
    async clearStaleData() {
      try {
      const postDb = this.getPostDb();

      const threshold = new Date();
      threshold.setHours(threshold.getHours() - 72); // 72 hours ago

      if (!postDb.table('posts')) {
        return;
      }

      // Delete posts older than 72 hours
      await postDb.table('posts').where('cachedAt').below(threshold.toISOString()).delete();
      } catch (error) {
      console.error(`Failed to clear stale data from IndexedDB: ${error}`);
      }
    },
    async createPost(postData, isRepost) {
      const authStore = useAuthStore();

      const method = isRepost ? 'post' : 'put';
      const endpoint = isRepost ? `/posts/repost` : `/posts`;

      if (!isRepost) {
        postData.groupName = postData.groupName || 'default';
        this.postEngagementQueue.push(postData.ulid);
        if (this.postEngagementQueue.length > 5) {
          this.fetchPostEngagements(this.postEngagementQueue);
          this.postEngagementQueue = [];
        }
      }

      if (isRepost && postData.body === '' && postData.images.length === 0) {
        const rePostData = {
          ulid: postData.ulid,
          title: "repost",
        }
        this.createRePost(rePostData, postData.ulid);
      }

      return apiBackendAuthAxios[method](endpoint, postData)
        .then(async (response) => {
          const post = isRepost ? response.data : response.data.data;
          if (postData.images) {
            post.images = postData.images.map((url) => ({ url }));
          }
          if (!isRepost) {
            post.user.userId = authStore.currentUser.ulid;
          }
          if (postData.groupId) {
           
            this.feedMaps[`group-${postData.groupId}`] = [post.id, ...this.feedMaps[`group-${postData.groupId}`]];

            return post;
         
          } else if (postData.groupName && postData?.groupName !== 'default') {
           
            this.feedMaps[`group-${postData.groupName}`] = [post.id, ...this.feedMaps[`group-${postData.groupName}`]];

            return post;
         
          }

          if (postData.groupId && (this.groups[postData.groupId]?.visibility === 'PRIVATE' || this.groups[postData.groupId]?.visibility === 'CHAT')) {
            return post;
          }
            this.addToFeedMap(post);

          return post;
        })
        .catch(this.handleError);
    },

    async createRePost(rePostData, parentItem) {
      try {
        const response = await apiBackendAuthAxios.post(`/posts/repost`, rePostData);
        let post = response.data;

        // Update the repost in the local cache
        this.postsCache[post.id] = post;
        await this.updatePostInDB(post); // Update IndexedDB with the new repost

        this.fetchPostEngagements([parentItem.id]);

        if (post.groupName === 'default') {
 
          this.addToFeedMap(post);
        }
        // update the parent post with the new repost
        let parentPost = this.postsCache[parentItem.id];
        parentPost.userEngagement.hasReposted = true;
        
        this.postsCache[parentItem.id] = parentPost;

        return post;
      } catch (error) {
        console.error(error);
        throw error; // Rethrow the error for further handling
      }
    },

    async addComment(commentData, parentId, userId) {

      try {
        const response = await apiBackendAuthAxios.put(`/comments`, commentData);
        const comment = response.data.data;


        const updatedComment = {
          ...comment,
          parentUlid: parentId,
          user: {
            userId: userId,
          }
        };

        await this.fetchPostEngagements([parentId]);

        // Initialize the feedMap for comments if it does not exist
        if (!this.feedMaps[`comments-${parentId}`]) {
          this.feedMaps[`comments-${parentId}`] = [];
        }

        // Add the new comment ID to the beginning of the array if it's not already in the feedMap
        if (!this.feedMaps[`comments-${parentId}`].includes(comment.id)) {
          this.feedMaps[`comments-${parentId}`] = [comment.id, ...this.feedMaps[`comments-${parentId}`]];
        }


        return updatedComment;
      } catch (error) {
        console.error("Error while commenting: ", error);
      }
    },

    cleanGiphyURL(url) {
      const baseURL = url.split(".gif")[0];
      return baseURL + ".gif";
    },

    handleError(error) {
      console.error(error)
      switch (error.response?.data?.error_type) {
        case 'authentication':
          return 'Authentication Failed'
        case 'Too many requests':
          return 'Too many attempts, try again in a few minutes'
        default:
          return 'Uh oh, something went wrong, try again'
      }
    },

    addToFeedMap(post) {
      const authStore = useAuthStore();
      const isInfluencer = authStore.currentUser.badges.includes('gold_verified');
      // Update feedMaps
      this.feedMaps.all.unshift(post.id)
      this.feedMaps.following.unshift(post.id)
      this.feedMaps.followingMutual.unshift(post.id)
      if (isInfluencer) {
        this.feedMaps.influencers.unshift(post.id)
      }

    },

    async pinPost(id, action) {
      try {
        let response

        if (action === 'add') {

          response = await apiBackendAuthAxios.put(`/posts/pinned`, {
            ulid: id
          })
        } else if (action === 'delete') {

          response = await apiBackendAuthAxios.delete(`/posts/pinned`, {
            data: {
              ulid: id
            }
          })
        }
        if (response?.data) {
          return { success: true, data: response.data } // If you want to return the data
        }

        return { success: true } // If you don't care about the response data
      } catch (error) {
        console.error('API Error:', error.response ? error.response.data : error.message)
        return {
          success: false,
          message: error.response ? error.response.data.message : 'Unknown error'
        }
      }
    },
    async getPinnedPost(username) {
      try {
        const response = await apiBackendAuthAxios.post('/posts/pinned/username', {
          username: username
        })
        return response.data.ulid
      } catch (error) {
        console.error(error)
        return null // Return null if there's an error
      }
    },

    async postInvalidation(postIds) {

      postIds.forEach(id => {
        delete this.postsCache[id];
      });
    },

    async removePostFromCache(postId) {

      delete this.postsCache[postId];
      this.deletePostInDB(postId);
      // Loop through each key in feedMaps and filter out the post ID
      Object.keys(this.feedMaps).forEach((key) => {
        this.feedMaps[key] = this.feedMaps[key].filter((item) => item !== postId);
      });

      const globalStore = useGlobalStore();

      // Add to the reportedPosts array
      globalStore.reportedPosts.push(postId);
    },

    async removePostsFromCache(userId) {

      const postsToRemoveFromIndexDb = [];

      // Iterate over the keys of postsCache
      for (let postId in this.postsCache) {
        // If the post's userId is equal to the userId passed to the function, delete the post
        if (this.postsCache[postId].user?.userId === userId) {
          // Remove post from the in-memory cache
          delete this.postsCache[postId];

          // Add the post ID to the array of posts to remove from IndexedDB
          postsToRemoveFromIndexDb.push(postId);

          // Remove all post IDs from the feedMaps
          Object.keys(this.feedMaps).forEach((mapKey) => {
            this.feedMaps[mapKey] = this.feedMaps[mapKey].filter((item) => item !== postId);
          });

        }
      }

      // Remove the posts from IndexedDB
      try {
        await this.deletePostsInDB(postsToRemoveFromIndexDb);

      } catch (error) {
        console.error(`Failed to delete posts from IndexedDB for userId ${userId}:`, error);
      }
    },

    async fetchCommentUlids(postId, sort, perPage = 20, currentPage = null) {

    try {
      const response = await apiBackendAuthAxios.post(`/comments?page=${currentPage}`, {
        ulid: postId,
        per_page: perPage,
        sort_by: sort,
      });

    const commentKey = sort + '-' + postId;
    
    this.processFeedMap('comments', response.data, commentKey, sort);

    return {
      last : response.data.last_page
    };
  } catch (error) {
    console.error(error);
    return {
      commentUlids: [],
      nextCursor: null
    };
  }
},


    async viewPosts(postIds) {
      // Remove duplicate post IDs and "END"
      if (postIds.length === 0) {
        return;
      }

      const uniquePostIds = [...new Set(postIds.filter((id) => id !== "END" && id !== null && id !== undefined))];
      try {
        await apiBackendAuthAxios.post('/posts/views', {
          ulids: uniquePostIds
        });
      
        for (const postId of uniquePostIds) {
          if (this.postsCache[postId]) {
          this.postsCache[postId].postEngagement.views += 1;
          }

          this.updatePostInDB(this.postsCache[postId]);
        }

      } catch (error) {
        console.error("Failed to record views for posts: ", error);

      }
    },

    async isProcessing() {

      const globalStore = useGlobalStore();

      // Remove nulls
      globalStore.processingPosts = globalStore.processingPosts.filter((item) => item !== null);

      const tempPosts = globalStore.processingPosts;

      if (tempPosts.length === 0) {
        return [];
      }

      try {
        const response = await apiBackendAuthAxios.post('/posts/map', { ulids: tempPosts });
        const posts = response.data.data;
        const notProcessingPosts = posts.filter((post) => !post.isProcessing);

        // Update the posts in the local cache and DB
        if (notProcessingPosts.length === 0) {
          return;
        }

        notProcessingPosts.forEach(post => {
          this.postsCache[post.id] = post;
          this.updatePostInDB(post);
        });

        // Remove the processed posts from the tempPosts
        globalStore.processingPosts = tempPosts.filter((ulid) =>
          !notProcessingPosts.some((post) => post.id === ulid));

      } catch (error) {
        console.error('Error fetching or processing posts:', error);
        // Handle the error appropriately
      }
    },

    async fetchViolations(postIds) {

      // Remove duplicate post IDs and "END"
      postIds = [...new Set(postIds.filter((id) => id !== "END"))];

      try {
        const response = await apiBackendAuthAxios.post('/posts/mapViolations', {
          ulids: postIds
        });

        const violations = response.data.data;
        violations.forEach(violation => {
          this.violationsCache[violation.id] = violation;
        });

        return violations;
      } catch (error) {
        console.error(error);
        return [];
      }
    },

    // Helper: Check the cache and IndexedDB for a given postId
    async checkCacheAndIndexDB(postId, postsCache) {
      if (postsCache[postId] && !postsCache[postId].isSkeleton) {
        // Post is in cache and not a skeleton

        return postsCache[postId];
      } else {
        // Check IndexedDB for the post
        try {
          const post = await this.getPostFromIndexDB(postId);
          // If post is found in IndexedDB, update the cache
          if (post) {
            postsCache[postId] = post;
          };
          return post || null; // If post is not found, return null
        } catch (error) {
          console.error(`Error checking IndexedDB for post: ${postId}`, error);
          return null; // Return null on error
        }
      }
    },

    // Helper: API call to fetch posts given an array of post IDs
    async fetchPostsFromAPI(batchIds) {

      //Remove nulls
      batchIds = batchIds.filter((id) => id !== null);

      if (batchIds.length === 0) {
        return [];
      };
      
      try {
        const response = await apiBackendAuthAxios.post('/posts/map', { ulids: batchIds });

        const newPosts = response.data.data;

        this.checkForHiddenPosts(newPosts, batchIds);
        
        return newPosts; // Return the posts data from the response

      } catch (error) {
        console.error(`Error fetching posts from API:`, error);
        return []; // Return empty array on error
      }
      
    },

    // Helper: Update cache and IndexedDB with new posts
    updateCacheAndIndexDB(newPosts) {

      try {

      newPosts.forEach(async (post) => {

        this.postsCache[post.id] = post; // Update the in-memory cache

        await this.updatePostInDB(post); // Update the post in IndexedDB
      });

    } catch (error) {
      console.error('Error updating cache and IndexedDB with new posts:', error);
    }
  },

    async fetchPostsByIds(postIds, update = false) {
      if (postIds.length === 0) {
        return [];
      }

      const userStore = useUserStore();

      // Remove duplicate post IDs, nulls, and "END" and sort in review id(ulid order)
      const uniquePostIds = [...new Set(postIds.filter(id => id !== "END" && id !== null))].sort();

      let cachedPostIds = [];

      if (!update) {

        const cachedPosts = await Promise.all(
        postIds.map(postId => this.checkCacheAndIndexDB(postId, this.postsCache))
      );

      //get postIds for cached posts
      cachedPostIds = cachedPosts.map(post => post?.id).filter(Boolean);
      }

      // Filter out posts that are already in cache
      const stillMissing = uniquePostIds.filter(postId => !cachedPostIds.includes(postId));

      this.initializePostSkeleton(stillMissing);

      // Fetch missing posts from the API
      if (stillMissing.length > 0) {
        // Fetch posts in batches of 20
        const BATCH_SIZE = 20;
        for (let i = 0; i < stillMissing.length; i += BATCH_SIZE) {
          const batch = stillMissing.slice(i, i + BATCH_SIZE);
          if (batch.length > 0) {
            const newPosts = await this.fetchPostsFromAPI(batch);

            this.updateCacheAndIndexDB(newPosts);
          }
        }
      }

    const allPosts = uniquePostIds.map(postId => this.postsCache[postId]).filter(Boolean)

    if (cachedPostIds) {

    this.postEngagementQueue = this.postEngagementQueue.concat(cachedPostIds);

      if (this.postEngagementQueue.length > 19) {
        this.fetchPostEngagements(this.postEngagementQueue);
        this.postEngagementQueue = [];
      }
    }

    const userIds = [...new Set(allPosts.map(post => post?.user?.userId).filter(Boolean))];

    if (userIds.length > 0) {

      userStore.fetchUsersByIds(userIds);
    }

    // Return the posts in the order they were requested
    return allPosts;
        
    },


    initializePostSkeleton(postId) {
      return {
        id: postId,
        isSkeleton: true,
        images: ['initial-placeholder'],
        initialFetch: true
      };
    },

    async fetchPostEngagements(postIds, filter = true) {
      if (postIds.length === 0) {
        return [];
      }

      // Remove duplicate post IDs and "END" and Null
      postIds = [...new Set(postIds.filter(id => id !== "END" && id !== null))];

      let filteredPostIds = postIds;

      if (filter) {
      // Prepare to check the 'cachedAt' property to filter out recently fetched posts
      const currentTime = new Date();
      const recentFetchThreshold = 15 * 1000; // 15 seconds in milliseconds

      // Filter postIds to exclude those fetched within the last 15
      filteredPostIds = await Promise.all(postIds.map(async (id) => {
        const post = await this.getPostFromIndexDB(id);
        if (post && post.cachedAt && (currentTime.getTime() - new Date(post.cachedAt).getTime() > recentFetchThreshold)) {
          return id;
        }
        return null; // Exclude recently fetched posts
      }));
    }

      // Filter out null values resulting from exclusion
      const eligiblePostIdsForEngagement = filteredPostIds.filter(id => id !== null);

      if (eligiblePostIdsForEngagement.length === 0) {
        return []; // No eligible posts for engagement fetch
      }

      const BATCH_SIZE = 20; // Adjust accordingly

      for (let i = 0; i < eligiblePostIdsForEngagement.length; i += BATCH_SIZE) {
        const batch = eligiblePostIdsForEngagement.slice(i, i + BATCH_SIZE);
        if (batch.length > 0) {
          try {
            const payload = { ulids: batch };
            const response = await apiBackendAuthAxios.post('/posts/engagement', payload);
            const engagements = response.data.data;

            if (engagements.length > 0) {

              await this.checkForHiddenPosts(engagements, batch);

              this.updatePostEngagementsInDB(engagements);
            }

          } catch (error) {
            console.error(`Error fetching engagements for batch: ${error}`);
          }
        }
      }
    },

    async checkForHiddenPosts(response, postIds) {

      if (response.length === 0) {
        postIds.forEach(postId => {
          this.removePostsFromCache(postId);
        });
        return;
      }

      // If Post in postIds and not in engagement remove from cache
      postIds.forEach(postId => {
        if (!response.some(response => response.id === postId)) {

          this.removePostsFromCache(postId);
        }
      });
    },

    // Helper function to update only the engagement data of a post in IndexedDB
        async updatePostEngagementsInDB(engagements, view = true, PostId) {
      try {

        let viewedPosts = [];

        // Now update engagements
        for (const engagement of engagements) {
          const existingPost = this.postsCache[engagement.id];
          if (existingPost && !existingPost.isSkeleton) {
            // Always update the post with the new engagement information
                existingPost.postEngagement = engagement.postEngagement;
                existingPost.updatedAt = engagement.updatedAt;
                if (existingPost.userReaction === null) {
                existingPost.userReaction = engagement.userReaction;
                }
                existingPost.isDeleted = engagement.isDeleted;
                existingPost.otherRepostUsers = engagement.otherRepostUsers;

            // Remove the post from the cache if it is hidden
            if (engagement.isHidden) {
                  this.removePostsFromCache(existingPost.id);
                  return;
                }

            // Update the cached post 
            this.postsCache[engagement.id] = existingPost;

            // Update the post in IndexedDB
            this.updatePostInDB(existingPost);

            viewedPosts.push(engagement.id);
          } 
        }
        
        // Finally, view the posts to update engagement metrics
        if (view) {
        // this.viewPosts(viewedPosts);
        }

      } catch (error) {
        console.error('Error updating post engagements in IndexedDB:', error);
      }
    },

    async deletePost(id, repost = false) {

      const post = this.postsCache[id];

      if (repost) {
        Object.keys(this.feedMaps).forEach((key) => {
          this.feedMaps[key] = this.feedMaps[key].filter((item) => item !== id)
        })

        return { success: true }
      }

      try {

        await apiBackendAuthAxios
          .delete('/posts/delete', {
            params: {
              ulid: id
            }
          })
          .then(async () => {

            await this.deletePostInDB(id);

            delete this.postsCache[id]

            this.fetchPostEngagements([post.parentUlid]);

            // Loop through each key in feedMaps and filter out the post ID
            Object.keys(this.feedMaps).forEach((key) => {
              this.feedMaps[key] = this.feedMaps[key].filter((item) => item !== id)
            })

            return { success: true }
          })
          .catch((error) => {
            console.error('error', error)
            return { success: false }
          })
      } catch (error) {
        console.error(error)
        // Return the error response
        return { success: false }
      }
    },

    clearFollowingFeedMap() {
        
        this.feedMaps.following = [];
        this.feedMaps.followingMutual = [];
    },

    clearPostCache() {

      this.postsCache = {},
        this.violationsCache = {},
        this.feedMaps = {
          all: [],
          following: [],
          xposts: [],
          followingMutual: [],
          profile: [],
          search: [],
          newUsers: [],
          comments: [],
          trending: [],
          influencers: [],
        }
    },

    async checkUsers(posts) {
      const userStore = useUserStore()

      const userIds = [...new Set(posts.map((item) => item.userId))]
      await userStore.fetchUsersByIds(userIds)
    },
    /*
     * This checkUsersPosts function collects missing user details from
     * a given set of posts' parents and updates a user store with the missing user information.
     */
    async checkUsersPosts(posts) {
      const userStore = useUserStore();

      // Extract the user IDs from the posts
      const userIds = Object.values(posts)
        .map(post => post?.user?.userId) // Directly map to the userId of each post
        .filter(userId => userId !== null && userId !== undefined); // Filter out null and undefined

      // Fetch the users; fetchUsersByIds will check cache, then IndexedDB, then API.
      await userStore.fetchUsersByIds(userIds);
    },

    async updatePostReaction(postId, reactionType, action) {
      try {
        let response;
        // API call to add or delete the post reaction
        if (action === 'add') {
          response = await apiBackendAuthAxios.put(`/post/reaction`, { ulid: postId, reaction: reactionType });
        } else if (action === 'delete') {
          response = await apiBackendAuthAxios.delete(`/post/reaction`, { data: { ulid: postId, reaction: reactionType } });
        }
        if (response.data.success) {
          return { success: true };
        } else {
          return { success: false };
        }
      } catch (error) {
        console.error(error);
        return { success: false };
      }
    },

    async getxPostsFeedMap() {

      try {
        const response = await apiBackendAuthAxios.get('/xposts/all')
        let xPosts = response.data.data
        this.checkUsers(xPosts)
        const newFeed = xPosts.map((feedItem) => feedItem.ulid)
        this.feedMaps.xposts = newFeed
        return this.feedMaps.xposts
      } catch (error) {
        console.error(error)
      }
    },

    async searchHashtags(query) {

      try {
        // Create a cancellation token source
        const cancelTokenSource = axios.CancelToken.source()

        // Store the cancellation token source in the store state
        this.cancelTokenSource = cancelTokenSource

        const response = await apiBackendAuthAxios.post(
          '/search/autocomplete/hashtag',
          {
            hashtag: query
          },
          {
            cancelToken: cancelTokenSource.token
          }
        )

        const { data } = response
        return data
      } catch (error) {
        if (axios.isCancel(error)) {
          // Request was cancelled
          console.error('Request cancelled:', error.message)
        } else {
          console.error('Error searching hashtags:', error)
        }
        return [] // Return an empty array if there's an error
      }
    },
    async searchTickers(query) {

      try {
        // Create a cancellation token source
        const cancelTokenSource = axios.CancelToken.source()

        // Store the cancellation token source in the store state
        this.cancelTokenSource = cancelTokenSource

        const response = await apiBackendAuthAxios.post(
          '/search/autocomplete/ticker',
          {
            ticker: query
          },
          {
            cancelToken: cancelTokenSource.token
          }
        )

        const { data } = response
        return data
      } catch (error) {
        if (axios.isCancel(error)) {
          // Request was cancelled
          console.error('Request cancelled:', error.message)
        } else {
          console.error('Error searching tickers:', error)
        }
        return [] // Return an empty array if there's an error
      }
    },

    // Add a new action to cancel the current search request
    cancelSearch() {

      if (this.cancelTokenSource) {
        // Cancel the current request using the cancellation token
        this.cancelTokenSource.cancel('Search request cancelled')
        // Reset the cancellation token source
        this.cancelTokenSource = null
      }
    },

    consolidatedPosts(posts) {

      let repostCounts = {};
      let consolidatedPosts = [];
      let nonReposts = [];
      let olderPostUlids = []; // Array to store ulids of older posts

      for (let post of posts) {

        if (post.isRepost && !post.isRepostWithComment) {

          if (repostCounts[post.parentUlid]) {

            repostCounts[post.parentUlid].count++;
            repostCounts[post.parentUlid].repostUsernames.push(post.username);
            if (post.id > repostCounts[post.parentUlid].recentPost.id) {

              olderPostUlids.push(repostCounts[post.parentUlid].recentPost.parentUlid); // Add ulid of older post
              repostCounts[post.parentUlid].recentPost = post;
            } else {

              olderPostUlids.push(post.parentUlid); // Add ulid of older post
            }
          } else {
            repostCounts[post.parentUlid] = {
              count: 1,
              recentPost: post,
              repostUsernames: [post.username],
            };
          }
        } else {
          nonReposts.push(post);
        }
      }

      for (let parentUlid in repostCounts) {

        let repost = repostCounts[parentUlid].recentPost;
        repost.additionalReposts = repostCounts[parentUlid].count - 1;
        repost.repostUsernames = repostCounts[parentUlid].repostUsernames;
        consolidatedPosts.push(repost);
      }

      // Combine consolidatedPosts and nonReposts
      const allPosts = [...consolidatedPosts, ...nonReposts];

      // Sort posts
      allPosts.sort((a, b) => {

        if (a.id < b.id) return 1;
        if (a.id > b.id) return -1;
        return 0;
      });

      return { consolidatedPosts: allPosts, removedReposts: olderPostUlids };
    },

    async processFeedMap(feedType, response, suffix = '', sort = 'newest') {

      const { data, next_cursor } = response;
      const feedKey = feedType + (suffix ? `-${suffix}` : '');
      const newFeed = data.map((feedItem) => feedItem.ulid);

      this.fetchPostsByIds(newFeed);

      //Create Skeletons
  //     if (feedType !== 'comments') {
  //     newFeed.forEach((ulid) => {
  //       if (!this.postsCache[ulid]) {
  //         this.postsCache[ulid] = this.initializePostSkeleton(ulid);
  //       }
  //     });
  //  }
      const parentUlids = data.filter(item => item.parentUlid).map(item => item.parentUlid);

      // Fetch details for parentUlids if they exist
      if (parentUlids.length > 0) {
        // parentUlids.forEach((ulid) => {
        //   if (!this.postsCache[ulid]) {
        //     this.postsCache[ulid] = this.initializePostSkeleton(ulid);
        //   }
        // });
       this.fetchParents(parentUlids);

      }

      this.checkUsers(data);
      this.checkAndUpdateUsers(data);
      const existingFeedMap = this.feedMaps[feedKey] || [];
      const updatedFeedMap = [...existingFeedMap, ...newFeed];

      const globalStore = useGlobalStore();

      let filteredFeedMap;
      // Remove reported posts from the feedMap
      if (feedType !== 'violations') {
        filteredFeedMap = updatedFeedMap.filter((ulid) => !globalStore.reportedPosts.includes(ulid));
      } else {
        filteredFeedMap = updatedFeedMap;
      }
      if (feedType === 'comments') {
        //only remove duplicates for comments
        if (sort === 'newest') {
          // Remove duplicates and sort by ulid
          this.feedMaps[feedKey] = [...new Set(filteredFeedMap)].sort(this.compareUlid);
        } else if (sort === 'oldest') {
          // Remove duplicates and sort by ulid
          this.feedMaps[feedKey] = [...new Set(filteredFeedMap)].sort(this.compareUlid).reverse();
        } else if (sort === 'popular' || sort === 'unpopular') {
          // Remove duplicates and sort by newFeed order
          this.feedMaps[feedKey] = [...new Set(filteredFeedMap)];
        } 
        
      } else {
        // Remove duplicates and sort by ULID
        this.feedMaps[feedKey] = [...new Set(filteredFeedMap)].sort(this.compareUlid);
      }

      return { feedMap: this.feedMaps[feedKey], next_cursor };
    },

    async fetchParents(parentUlids) {

      const parentPosts = await this.fetchPostsByIds(parentUlids);

      // If parent post is repost_with_comment, comment and reply then fetch the parent of the parent if not already in parentPosts
      const grandparentUlids = parentPosts.filter(post => post.isRepostWithComment || post.postType === "COMMENT" || post.postType === "REPLY").map(post => post.parentUlid);

      // Remove duplicates and ulids already present in parentUlids
      const uniqueGrandparentUlids = grandparentUlids.filter(ulid => !parentUlids.includes(ulid));

      this.fetchPostsByIds(uniqueGrandparentUlids);
    },

    async checkAndUpdateUsers(feedmap) {

      const userStore = useUserStore(); // Import the user store as needed

      // Create an array to store user IDs that need to be updated
      let usersToUpdate = [];

      const allUsers = new Map();


      for (const item of feedmap) {

        const user = await userStore.getUser(item.userId);
        if (user) {

          // Check if the user information is available
          const userUpdatedAt = item.userUpdatedAtEpoch;
          const lastUpdatedAt = user.updatedAtEpoch;

          // Add the user to the allUsers map
          allUsers.set(item.userId, { userId: item.userId, lastActiveAt: item.lastActiveAt });

          if (userUpdatedAt > lastUpdatedAt) {

            // If userUpdatedAt is greater, add the user ID to the update array
            usersToUpdate.push(item.userId);
          }
        }
      }

      for (let [userId, user] of allUsers) {
    userStore.updateLastActiveAt(userId, user.lastActiveAt);
  }

      if (usersToUpdate.length > 0) {

        //Depulicate the usersToUpdate array and remove nulls undefined ect
        usersToUpdate = [...new Set(usersToUpdate.filter((item) => item !== null))];
        // Fetch all the users in one batch
        await userStore.fetchUsersFromApi(usersToUpdate);
      }
    },

    async checkForNewPosts(feedType, lastUlid) {

      // Only proceed for the specified feedTypes
      if (!['all', 'following', 'followingMutual', 'newUsers'].includes(feedType)) {

        console.warn(`Unsupported feedType: ${feedType}`);
        return;
      }

      // Validate the lastUlid for the input feedType
      if (lastUlid === null) {

        console.warn(`No lastUlid found`);
        return;
      }

      try {
        const response = await apiBackendAuthAxios.post(`/posts/${feedType}`, {
          ulid: lastUlid,
        });
        if (response.data.data.length > 0) {

          const { data, next_cursor } = response.data;
          this.checkAndUpdateUsers(data);

          return response.data.data;
        }

      } catch (error) {
        console.error(`Error fetching new posts for ${feedType}:`, error);
      }
    },

  async checkForNewPostsPusher() {
    const globalStore = useGlobalStore();
    const authStore = useAuthStore();
    const currentFeed = globalStore.currentFeed;

    // Check if the current feed is relevant for new posts checking
    if (['profile', 'search', 'group'].includes(currentFeed)) {
      return; // Early exit if the feed is not one of the targeted types
    }

    // Check if feedMaps has an entry for the current feed and validate the last ULID
    if (!this.feedMaps[currentFeed]) {
      console.warn(`No feed map available for ${currentFeed}`);
      return;
    }

    const lastUlid = this.feedMaps[currentFeed][0];
    if (lastUlid === 'END') {

      this.feedMaps[currentFeed] = []; // Clear feed map if last ULID is 'END'
      return;
    }

    const endpoint = currentFeed === 'violations' ? `/moderation/posts/${currentFeed}` : `/posts/${currentFeed}`;

    try {
      const response = await apiBackendAuthAxios.post(endpoint, { ulid: lastUlid });
      if (response.data.data.length > 0) {
        const { data } = response.data;
        this.checkAndUpdateUsers(data);

        const existingPostUlids = this.collectExistingPostUlids(this.newPosts, this.feedMaps, currentFeed);
        const uniqueNewPosts = this.filterNewPosts(data, existingPostUlids, authStore.currentUser.ulid);

        this.newPosts[currentFeed] = [...(this.newPosts[currentFeed] || []), ...uniqueNewPosts];

        return data; // Return the new posts for further processing
      }
    } catch (error) {
      console.error(`Error fetching new posts for ${currentFeed}:`, error);
      // Optionally throw an error or return an error status
      throw new Error(`Failed to fetch new posts for ${currentFeed}`);
  }
},

async fetchNewGroupPosts(groupId, lastUlid = null) {
  const authStore = useAuthStore();
  try {
    // Handle lastUlid when it's null or undefined
    if (!lastUlid) {
      lastUlid = this.feedMaps[`group-${groupId}`][0];
    }

    if (lastUlid === 'END') {
      return;
    }

    const currentFeed = `group-${groupId}`;

    const response = await apiBackendAuthAxios.post(`/posts/group`, { groupId: groupId, ulid: lastUlid });
    if (response.data.data.length > 0) {
      const { data } = response.data;
      this.checkAndUpdateUsers(data);

    const existingPostUlids = this.collectExistingPostUlids(this.newPosts, this.feedMaps, currentFeed);
        const uniqueNewPosts = this.filterNewPosts(data, existingPostUlids, authStore.currentUser.ulid);

        this.newPosts[currentFeed] = [...(this.newPosts[currentFeed] || []), ...uniqueNewPosts];

      return data; // Return the new posts for further processing
    } else {
      return []; // Return an empty array if no new posts are found
    }
  } catch (error) {
    console.error(`Error fetching new posts for group ${groupId}:`, error);
    throw new Error(`Failed to fetch new posts for group ${groupId}`);
  }
},

   // Fetch a feed of posts from a group
    async fetchGroupFeedMap(groupName, cursor) {

      //check if groupName is a ULID
      const ulidRegex = /^[0123456789ABCDEFGHJKMNPQRSTVWXYZ]{26}$/;

      // Check if groupName is a ULID
      const isUlid = ulidRegex.test(groupName.toUpperCase());

      const payload = isUlid ? { groupId: groupName } : { groupName: groupName };

      const params = cursor ? { cursor } : {};
      try {
        const response = await apiBackendAuthAxios.post(
          '/posts/group',
          payload,
          { params }
        )

        return this.processFeedMap('group', response.data, groupName)
      } catch (error) {
        return this.handleApiError(error)
      }
    },

    async fetchGroupList(type) {
      try {
        
        const requestData = type !== 'new' ? { type } : {};
        
        const response = await apiBackendAuthAxios.post('/groups/search', requestData);

        let groups = response.data.data;

        if (this.groupList[type].length) {

          groups = groups.filter(group => !this.groupList[type].includes(group.id));
      
          this.groupList[type] = [...this.groupList[type], ...groups.map(group => group.id)];
        } else {
          this.groupList[type] = groups.map(group => group.id);
        }

        this.fetchGroups(groups.map(group => group.id));
        
        return response.data.data;
      } catch (error) {
        console.error(error);
      }
    },

async fetchGroups(groupIds, update = false) {

  // Check if the groupIds are already in the groupCache
  if (update) {
    groupIds = groupIds.filter(groupId => !this.groups[groupId]);
  }

  if (groupIds.length === 0) {
  return [];
  }


  try {
    const response = await apiBackendAuthAxios.post('/groups/map', { ulids: groupIds });
    let groups = response.data.data;

    // Update the groupCache with the new groups
    groups.forEach(group => {
      this.groups[group.id] = group;
    });

    return groups;
  } catch (error) {
    console.error(error);
  }
},

async joinGroup(groupId, userId) {
  const userStore = useUserStore();
  
  try {
    const response = await apiBackendAuthAxios.post('/group/join', { groupId });
    
    this.groups[groupId].isJoined = true;

    // Ensure the member count exists and increment it
    if (this.groups[groupId].membersCount != null) {
      this.groups[groupId].membersCount += 1;
    } else {
      this.groups[groupId].membersCount = 1;
    }

    // Add the current user ID to groupMembers
    if (userStore.groupMembers[groupId]) {
      userStore.groupMembers[groupId].push(userId);
    } else {
      userStore.groupMembers[groupId] = [userId];
    }

    // Add the group to the groupList.myGroups
    this.groupList.myGroups.push(groupId);
    
    return response.data;

  } catch (error) {
    console.error(error);
    return error;
  }
},

async leaveGroup(groupId, userId) {
  const userStore = useUserStore();

  try {
    const response = await apiBackendAuthAxios.delete('/group/join', { data: { groupId } });

    this.groups[groupId].isJoined = false;

    // Ensure the member count exists and decrement it without going below zero
    if (this.groups[groupId].membersCount != null && this.groups[groupId].membersCount > 0) {
      this.groups[groupId].membersCount -= 1;
    } else {
      this.groups[groupId].membersCount = 0;
    }

    // Check if groupMembers exists for the groupId before filtering
    if (userStore.groupMembers && userStore.groupMembers[groupId]) {
      userStore.groupMembers[groupId] = userStore.groupMembers[groupId].filter(memberId => memberId !== userId);
    }

    //Remove the group from the groupList.myGroups and groupList.pinned
    this.groupList.myGroups = this.groupList.myGroups.filter(group => group !== groupId);
    this.groupList.pinned = this.groupList.pinned.filter(group => group !== groupId);

    return response.data;
  } catch (error) {
    console.error(error);
  }
},

async deletePostFromGroup(groupId, postId) {
  try {
    const response = await apiBackendAuthAxios.delete('/groups/post', { data: { groupId, postId } });
    this.removePostsFromCache(postId);
    this.feedMaps[`group-${groupId}`] = this.feedMaps[`group-${groupId}`].filter(post => post !== postId);
    return response.data;
  } catch (error) {
    console.error(error);
  }
},

async removeMemberFromGroup(groupId, userId) {
  try {
    const response = await apiBackendAuthAxios.post('/group/ban', { data: { groupId, userId } });
    return response.data;
  }
  catch (error) {
    console.error(error);
  }
},

async togglePinGroup(groupId, isPinned) {
  const method = isPinned === "unpin" ? 'delete' : 'post';
  const url = '/group/pin';

  try {
    let response;
    if (method === 'delete') {
      response = await apiBackendAuthAxios.delete(url, { data: { groupId: groupId } });
    } else {
      response = await apiBackendAuthAxios.post(url, { groupId: groupId });
    }
    this.groups[groupId].isPinned = !isPinned;

    if (isPinned === "unpin") {
      this.groupList.pinned = this.groupList.pinned.filter(group => group !== groupId);
    } else if (isPinned === "pin") {
      this.groupList.pinned.push(groupId);
    }

    return response;
  } catch (error) {
    console.error('error', error);
    return error;
  }
},

    async joinChat(groupName) {
       try {
        const response = await apiBackendAuthAxios.post('/group/join', { groupName });
        return response.data;
      }
      catch (error) {
        console.error(error);
      }
    },

    async createChat(payload) {

      const authStore = useAuthStore();

      const { body, images = [], groupName, visibility = "CHAT" } = payload;

      try {
        // Step 1: Create a private group
        const groupPayload = {
          name: groupName,
          visibility,
        };
        await apiBackendAuthAxios.put('/group/add', groupPayload);
        // Step 2: Send the first message in the created group
        const messagePayload = {
          groupName,
          body,
          images,
        };
        const response = await apiBackendAuthAxios.put('/posts', messagePayload);
        // Process response data
        const post = response.data.data;
        if (images.length > 0) {
          post.images = images.map(url => ({ url }));
        }
        post.user.userId = authStore.currentUser.ulid;
        // Add post to IndexedDB and local cache
        await this.updatePostInDB(post);
        this.postsCache[post.id] = post;

        this.feedMaps[`group-${groupName}`] = [post.id];

        return post;
      } catch (error) {
        this.handleError(error);
      }
    },

    async createGroup (payload, update = false) {

      // set put or patch based on update
      const method = update ? 'patch' : 'put';

      const route = update ? '/group/edit' : '/group/add';
      
      try {
        const response = await apiBackendAuthAxios[method](route, payload);

        // Update the groupList with the new group
      
        if (!update) {
          this.groupList.push(response.data.id);

        } else {
          const index = this.groupList.indexOf(payload.id);
          this.groupList[index] = payload.id;
        }

        this.groups[response.data.id] = response.data;

        return response.data;
      } catch (error) {
        return error;
      }
    },

    async deleteGroup(groupId) {
      try {
        const response = await apiBackendAuthAxios.delete('/group', { params: { groupId } });

        // Remove the group from the groupList
        this.groupList = this.groupList.filter(group => group.id !== groupId);

        return response.data;
      } catch (error) {
        console.error(error);
      }
    },

    async fetchChatList() {
      try {
        const response = await apiBackendAuthAxios.get('/chat/groups');

        this.chatList = response.data.data;
        return response.data.data;
      } catch (error) {
        console.error(error);
      }
    },

  // Utility function to collect existing ULIDs from newPosts and feedMaps
  collectExistingPostUlids(newPosts, feedMaps, feed) {
    const newPostUlids = new Set(newPosts[feed]?.map(post => post.ulid));
    const feedMapUlids = new Set(feedMaps[feed]?.map(post => post.ulid));
    return new Set([...newPostUlids, ...feedMapUlids]); // Merge both sets
  },

  // Function to filter out posts that already exist or are from the current user
  filterNewPosts(posts, existingUlids, currentUserUlid) {
    return posts.filter(post => !existingUlids.has(post.ulid) && post.ulid !== currentUserUlid);
  },


    compareUlid(a, b) {

      return b.localeCompare(a)
    },
    handleApiError(error) {

      console.error(error)
      return { feedMap: [], next_cursor: null }
    },
    async fetchFeedMap(feedType, cursor) {

      const globalStore = useGlobalStore();

      console.log(globalStore.parler, 'parler')

      const route = globalStore.parler ? '/posts/' : '/videos/';

      const params = cursor ? { cursor } : {};
      try {
        const response = await apiBackendAuthAxios.get(route + feedType, { params })

        if (!globalStore.parler) {
          feedType = 'videos-' + feedType;
        }
    

        return this.processFeedMap(feedType, response.data)

      } catch (error) {

        return this.handleApiError(error)
      }
    },

    async fetchVideoFeedMap(feedType, cursor) {
        
        const params = cursor ? { cursor } : {};
        try {
          const response = await apiBackendAuthAxios.get('/videos/' + feedType, { params })
  
          return this.processFeedMap(feedType, response.data)
  
        } catch (error) {
  
          return this.handleApiError(error)
        }
      },

    async fetchModerationFeedMap(feedType, cursor) {

      const params = cursor ? { cursor } : {};
      try {
        const response = await apiBackendAuthAxios.post('/moderation/posts/violations', { params })

        return this.processFeedMap(feedType, response.data)

      } catch (error) {

        return this.handleApiError(error)
      }
    },
    async fetchUserFeedMap(feedType, username, searchType, page, pageSize, cursor) {

      const encodedUsername = encodeURIComponent(username);

      const params = cursor ? { cursor } : {};
      if (searchType === 'media') {

        try {
          const response = await apiBackendAuthAxios.get('/profile/' + encodedUsername + '/feedImagesOnly', { params })
          const suffix = `${username}-${searchType}`
          return this.processFeedMap(feedType, response.data, suffix)

        } catch (error) {
          return this.handleApiError(error)
        }
      } else if (searchType === 'comments') {
        try {
          const response = await apiBackendAuthAxios.get('/profile/' + encodedUsername + '/feedCommentsOnly', {
            params: {
              cursor
            }
          })
          const suffix = `${username}-${searchType}`
          return this.processFeedMap(feedType, response.data, suffix)
        }
        catch (error) {
          return this.handleApiError(error)
        }
      } else if (searchType === 'videos') {
        try {
          const response = await apiBackendAuthAxios.get('/profile/' + encodedUsername + '/videos', {
            params: {
              cursor
            }
          })
          const suffix = `${username}-${searchType}`
          return this.processFeedMap(feedType, response.data, suffix)
        }
        catch (error) {
          return this.handleApiError(error)
        }

      } else {
        try {
          const response = await apiBackendAuthAxios.get('/profile/' + encodedUsername + '/feed', {
            params: {
              cursor
            }
          })
          return this.processFeedMap(feedType, response.data, username)
        }
        catch (error) {
          return this.handleApiError(error)
        }
      }
    },
    // Fetch a feed based on a search
    async searchFeedMap(feedType, searchType, query, page, pageSize, cursor) {
      const params = cursor ? { cursor } : {};

      // Handler for individual search type
      const searchByType = async (type) => {


        const payloadKey = type === 'username' ? 'ulid' : type;

        const payload = type === 'username' ? this.searchedUserUlid : query;
        try {
          const response = await apiBackendAuthAxios.post(
            `/search/posts/${type}`,
            { [payloadKey]: payload },
            { params }
          );
          const suffix = `${type}-${query}`;
          return this.processFeedMap(feedType, response.data, suffix);
        } catch (error) {
          return this.handleApiError(error);
        }
      };

      if (searchType === 'posts') {
        // Perform all searches in parallel
        try {
          let allSearchTypes = ['username', 'hashtag'];

          const allResults = await Promise.all(allSearchTypes.map(type => searchByType(type)));

          // Combine all feedmaps into one, deduplicate if necessary
          const combinedFeedMap = allResults.reduce((acc, result) => {
            if (Array.isArray(result.feedMap)) {
              result.feedMap.forEach(post => {
                if (!acc.includes(post)) {
                  acc.push(post);
                }
              });
            }
            return acc;
          }, []);

          //Order by ULID
          combinedFeedMap.sort(this.compareUlid);

          const suffix = `${searchType}-${query}`;

          const feedKey = 'search' + (suffix ? `-${suffix}` : '');

          this.feedMaps[feedKey] = combinedFeedMap;

          // Optionally, sort combinedFeedMap if needed, e.g., by post date

          return { feedMap: combinedFeedMap, next_cursor: allResults[0].next_cursor };
        } catch (error) {
          return this.handleApiError(error);
        }
      } else {
        // Handle single search type
        return searchByType(searchType);
      }
    },

    async fetchTrendingPosts(cursor = null) {
      try {
        const response = await apiBackendAuthAxios.get('/trending/posts/last24', { params: { cursor: cursor } })

        if (response.data.posts.length === 0) {
          return [];
        }

        const limitedPosts = response.data.posts.slice(0, 20);

        this.feedMaps.trending = limitedPosts

        this.fetchPostsByIds(limitedPosts);

        return {feedMap: limitedPosts, next_cursor: response.data.next_cursor || null};
      } catch (error) {
        console.error(error)
        return []
      }
    },

    async fetchTrendingHashtags() {

      this.numberofTrendingHashtagFetches += 1;

      if (this.numberofTrendingHashtagFetches > 3) {
        this.fetchTrending = true;
        this.numberofTrendingHashtagFetches = 0;
      }

      if (this.trendingHashtags.length > 0 || !this.fetchTrending) {
        return this.trendingHashtags;
      }
      try {
        const response = await apiBackendAuthAxios.get('/trending/hashtags/last24')

        this.trendingHashtags = response.data.hashtags;

        return response.data.hashtags;
      } catch (error) {
        console.error(error)
        return []
      }
    },

    /**
     * Constructs a unique feed key based on the given parameters.
     *
     * @param {string} feedType - The type of feed.
     * @param {string} feedParams - The parameters specific to the feedType.
     * @param {string} searchType - The type of search.
     *
     * @return {string} The constructed unique feed key.
     */
    constructFeedKey(feedType, feedParams, searchType) {

      let uniqueFeedKey = feedType

      if (feedType === 'search' && searchType) {
        uniqueFeedKey = `${feedType}-${searchType}-${feedParams}`
      } else if (feedType === 'profile' && feedParams && searchType === 'media') {
        uniqueFeedKey = `${feedType}-${feedParams}-${searchType}`
      } else if (feedType === 'profile' && feedParams) {
        uniqueFeedKey = `${feedType}-${feedParams}` // Assuming feedParams here is the username
      }

      return uniqueFeedKey
    },

    /**
     * Fetches missing posts from the given feed.
     *
     * @param {Array} feed - The feed containing post IDs.
     * @return {Promise} - A promise that resolves when the missing posts are fetched.
     */
    async fetchMissingPosts(feed) {

      const missingPostIds = feed.filter((postId) => !this.postsCache[postId])
      if (missingPostIds.length > 0) {
        await this.fetchPostsByIds(missingPostIds)
      }
    },

    /**
     * Returns an array of XPost feeds based on the xpostsIndex provided.
     * @return {Array} - An array of XPost feeds.
     */
    getXPostFeedArray() {

      if (!this.feedMaps.xposts || this.feedMaps.xposts.length === 0) {
        return [];
      };

      return Object.entries(this.postsCache)
        .filter(([postId]) => this.feedMaps.xposts.includes(postId))
        .map(([, post]) => post)
    },

    /**
     * Returns an array of normal post objects from the provided feed.
     *
     * @param {string[]} feed - An array of post IDs representing the feed.
     * @returns {Object[]} - An array of normal post objects from the provided feed.
     */
    getNormalPostFeedArray(feed) {

      const normalPosts = Object.entries(this.postsCache)
        .filter(([postId]) => feed.includes(postId))
        .map(([, post]) => post);

      normalPosts.sort((a, b) => b.id.localeCompare(a.id));

      return normalPosts;
    },

    /**
     * Fetches data from the store.
     *
     * @param {string} feedType - The type of feed.
     * @param {object} feedParams - The parameters for the feed.
     * @param {string} searchType - The type of search.
     * @param {string} next_cursor - The next cursor.
     * @return {object} An object containing the fetched posts and the next cursor.
     */
    async fetchFromStoreData(feedType, feedParams, searchType, next_cursor) {

      // Construct a unique feed key based on the given parameters
      const uniqueFeedKey = this.constructFeedKey(feedType, feedParams, searchType)

      // Get the feed by the unique feed key
      let feed = this.feedMaps[uniqueFeedKey];

      // If there are no posts in the feed, fetch the feed data, or feed is undefined
      if (!feed || feed?.length < 1) {
        // Fetch the feed data
        next_cursor = (
          await this.fetchFeedData(feedType, feedParams, searchType, 1, this.sliceFeed, null, next_cursor)
        ).next_cursor
        feed = this.feedMaps[uniqueFeedKey];
      }

      // If there are no posts in the feed, return an empty array
      if (!feed) {
        return [];
      }
      // Sort the feed by ulid
      feed.sort(this.compareUlid)
      // Fetch missing posts
      await this.fetchMissingPosts(feed)
      // Get the XPost feeds
      const xPostsFeedArray = this.getXPostFeedArray();
      // Get the normal post feeds
      const normalFeedArray = this.getNormalPostFeedArray(feed);

      // Check if the normal feed has less than 10 posts
      if (normalFeedArray.length < 10) {

        // If it has less than 10 posts, don't insert XPost feeds
        return { posts: normalFeedArray, next_cursor: next_cursor };
      }

      // Randomly insert the XPost feeds into the normal post feeds
      let posts =
        normalFeedArray.length > 0 ? randomInsert(normalFeedArray, xPostsFeedArray, normalFeedArray.length / 50, normalFeedArray.length / 10) : [];
      // Return the posts and the next cursor

      return { posts: posts, next_cursor: next_cursor };
    },

    // Function to fetch the feedmap by type
    async fetchFeedMapByType(feedType, feedParams, searchType, page, pageSize, cursor) {

      let feedMap, next_cursor;

      // Fetch the feed map based on the feed type
      switch (feedType) {

        case 'profile':
          ({ feedMap, next_cursor } = await this.fetchUserFeedMap(
            feedType,
            feedParams,
            searchType,
            page,
            pageSize,
            cursor
          ));
          break;
        case 'search':
          ({ feedMap, next_cursor } = await this.searchFeedMap(
            feedType,
            searchType,
            feedParams,
            page,
            pageSize,
            cursor
          ));
          break;
        case 'group':
          ({ feedMap, next_cursor } = await this.fetchGroupFeedMap(
            feedParams,
            cursor,            
          ));
          break;
        case 'trending':

          ({feedMap, next_cursor} = await this.fetchTrendingPosts(cursor));

          break;

        case 'violations':
          ({ feedMap, next_cursor } = await this.fetchModerationFeedMap(feedType, cursor));
          break;
          
        default:
          ({ feedMap, next_cursor } = await this.fetchFeedMap(feedType, cursor));
      };

      return { feedMap, next_cursor };
    },
    /*
     * Fetches the feed data for the given feed type
     */
    async fetchFeedData(feedType, feedParams, searchType, initialPosition, page, pageSize, cursor) {



      if ((feedType === 'following' || feedType === 'followingMutual') && this.refreshFeedMapsOnNextFetch) {
        this.clearFollowingFeedMap();
        cursor = null;
        this.refreshFeedMapsOnNextFetch = false;
      }
   
      // if ((feedType === "following" || feedType === "all" || feedType === "followingMutual") && 
      //     this.feedMaps[feedType].length > 5 && !initialPosition) {

      //   return {
      //     posts: this.feedMaps[feedType],
      //     next_cursor: cursor, 
      //     hasMore: cursor ? true : false
      //   };
      // }

      const returnedFeedMap = await this.fetchFeedMapByType(feedType, feedParams, searchType, page, pageSize, cursor);


      let feedMap = returnedFeedMap.feedMap;
      let next_cursor = returnedFeedMap.next_cursor;


      // Fetch the posts
      if (feedMap && feedMap.length > 0) {

        if (feedType === 'violations') {
          await this.fetchViolations(feedMap);
        } 
      }

      // Update the hasMoreItems flag
      this.hasMoreItems = next_cursor !== null;

      return { posts: feedMap, next_cursor, hasMore: this.hasMoreItems };
    },

    async fetchXposts() {

      const xPostIds = await this.getxPostsFeedMap();

      this.fetchPostsByIds(xPostIds);

    },

    async fetchInitialFeed(feedType, feedParams, searchType, initialPosition, pageSize) {
      return this.fetchFeedData(feedType, feedParams, searchType, initialPosition, pageSize)
    },

    async fetchMoreFeed(feedType, feedParams, searchType) {

      this.page += 1
      return this.fetchFeedData(feedType, feedParams, searchType, this.page, this.cursor)
    }
  },

})
