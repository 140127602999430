<template>
  <LoadingAnimation v-if="loading && !isNestedFeed" />
  <div
    v-else-if="!isNarrow"
    ref="commentFeedRef"
    :class="{ 'ml-1 w-full': isNestedFeed }"
  >
    <div class="right-0 w-full">
      <div class="flex items-center align-baseline justify-between">
        <h1 v-if="!isNestedFeed && !video" class="text-black dark:text-white">
          <span class="font-bold">Comments</span>
        </h1>
        <div class="flex items-center">
          <select
            v-if="!isNestedFeed"
            v-model="selectedSortMethod"
            class="ml-1 py-1 px-1 rounded-md bg-white text-black dark:bg-black dark:text-white"
          >
            <option value="popular">Popular</option>
            <option value="unpopular">Unpopular</option>
            <option value="newest">Newest</option>
            <option value="oldest">Oldest</option>
          </select>
        </div>
      </div>
      <DynamicScroller
        v-if="commentFeed && commentFeed.length > 0"
        :items="commentFeed ? commentFeed : []"
        :min-item-size="170"
        :prerender="20"
        :buffer="2000"
      >
        <template #default="{ item, index, active }">
          <DynamicScrollerItem
            v-if="item?.id && item.isPostSkeleton !== true"
            :item="item"
            :key="item.id"
            :active="active"
            :size-dependencies="[item.images, item.postType, item.isRepostWithComment]"
            :data-index="index"

          >
            <comment-details
              :parentItemId="item.id"
              :index="index"
              :isNestedFeed="true"
              :parentSort="selectedSortMethod"
              :detailShow="detailsShow"
              :depth="depth"
              @comment-id="loadMoreComments"
              @comment-ids="addCommentIds"
              @comment-deleted="removeComment"

            />
          </DynamicScrollerItem>
          <post-skeleton v-else :postId="item?.id ?? 'blank'" />
        </template>
      </DynamicScroller>
      <div v-if="loadingMore" class="flex justify-center pt-3">
        <ion-spinner color="primary" class="text-4xl" />
        </div>
      <div v-if="shouldShowLoadMoreButton && !detailsShow" class="flex justify-center pt-2">
        <button
          class="cursor-pointer select-none"
          @click="navigateToContent(parentItem.id)"
        >
          <p class="font-bold text-blue-500">Load More</p>
        </button>
      </div>
    </div>
    <!-- <button class="ml-8 cursor-pointer select-none" v-if="shouldShowLoadMoreButton && !detailsShow" @click="loadMoreComments">
      <p class="font-bold text-blue-500">Load More</p>
    </button> -->
  </div>
  <div v-else>
    <p
      class="text-primary p-3 cursor-pointer select-none"
      @click="navigateToContent(parentId)"
    >
      View More
    </p>
  </div>
</template>

<script setup>
import { ref, watch, onBeforeMount, onMounted, computed, onBeforeUnmount } from "vue";
import { useRouter } from "vue-router";
import { usePostStore } from "@/stores/post";
import { useAuthStore } from "@/stores/auth";
import { useGlobalStore } from "@/stores/global";
import CommentDetails from "@/components/comments/CommentDetails.vue";
import { useUserStore } from "../../stores/users";
import LoadingAnimation from "../LoadingAnimation.vue";
import { IonSpinner } from "@ionic/vue";
import { DynamicScroller, DynamicScrollerItem } from "vue-virtual-scroller";
import PostSkeleton from "@/components/posts/PostSkeleton.vue";

// Define properties
const props = defineProps({
  parentItem: {
    type: Object,
    required: true,
  },
  parentId: {
    type: String,
    required: false,
  },
  isNestedFeed: {
    type: Boolean,
    default: false,
  },
  detailsShow: {
    type: Boolean,
    default: false,
  },
  parentFilter: {
    type: Boolean,
    default: null,
  },
  parentSort: {
    type: String,
    defualt: null,
  },
  depth: {
    type: Number,
    default: 0,
  },
  loadMore: {
    type: Boolean,
    default: false,
  },
  video: {
    type: Boolean,
    default: false,
  }, 
});

const authStore = useAuthStore();
const postStore = usePostStore();
const globalStore = useGlobalStore();
const userStore = useUserStore();
const commentFeedRef = ref(null);
const commentIds = computed(
  () => postStore.feedMaps[`comments-${selectedSortMethod}-${props.parentItem.id}`] ?? []
);
const perPage = computed(() => props.detailsShow ? 20 : 3);
const subCommentIds = ref([]);
const loading = ref(true);
const loadingMore = ref(false);
const defaultSort = computed(() => globalStore.settings.comment_sort || "newest");

const selectedSortMethod = ref(props.parentSort ?? defaultSort.value);

const commentFeedIds = computed(() => postStore.feedMaps[`comments-${selectedSortMethod.value}-${props.parentItem.id}`] ?? []);

const commentFeed = computed(() => {
  const feedIds = commentFeedIds.value;
  const feedPosts = feedIds.map((id) => postStore.postsCache[id]).filter(item => item !== undefined);
  return props.detailsShow ? feedPosts : feedPosts.slice(0, 3);
});

watch (
  () => commentFeedIds.value,
  (newVal) => {
    if (newVal.length > 0) {
      loading.value = false;
    }
  }
);

const loadMoreComments = (id) => {
  // Load more if the commentId is the last one or the tenth from the bottom
  if (commentFeedIds.value.length > 0 && !loadingMore.value && currentPage !== lastPage) {
    const lastId = commentFeedIds.value[commentFeedIds.value.length - 1];
    const tenthFromBottomIndex = commentFeedIds.value.length - 10;
    const tenthFromBottomId = commentFeedIds.value[tenthFromBottomIndex] || lastId;

    if ((id === lastId || id === tenthFromBottomId) && currentPage <= lastPage) {
      loadingMore.value = true;
      getCommentFeed();
    }
  }
};

const commentCount = computed(() => props.parentItem.postEngagement.commentCount);

const emit = defineEmits(["comment-ids", "scroll-into-view"]);

// 4. Config and Flags
const hasCommentFeed = ref(false);


let observer;
let refreshInterval;

// 5. External Utilities
const router = useRouter();

onBeforeMount(() => {});

onMounted(async () => {
  if (props.parentItem.postEngagement.commentCount > 0) {
    await getCommentFeed();
  }

  // Emit the comment IDs to the parent
  if (commentFeedRef.value !== null) {
    observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (commentIds.value) {
            postStore.visiblePosts = [...postStore.visiblePosts, ...commentIds.value];

            if (subCommentIds.value) {
              const all = [...commentIds.value, ...subCommentIds.value];
              emit("comment-ids", all);
            } else {
              emit("comment-ids", commentIds.value);
            }
          }
        }
      });
    });

    // Begin observing the root element of the component
    observer.observe(commentFeedRef.value);

    refreshInterval = setInterval(() => {
      if (commentIds.value) {
        if (subCommentIds.value) {
          const all = [...commentIds.value, ...subCommentIds.value];
          emit("comment-ids", all);
        } else {
          emit("comment-ids", commentIds.value);
        }
      }
    }, 30 * 1000); // Refresh every 30 seconds
  }
});

onBeforeUnmount(() => {
  // postStore.stripPostsToSkeleton(commentIds.value);

  // Stop observing the root element of the component
  if (observer) {
    observer.disconnect();
  }

  // Clear the refresh interval
  if (refreshInterval) {
    clearInterval(refreshInterval);
  }
});

const addCommentIds = (ids) => {
  subCommentIds.value = [...subCommentIds.value, ...ids];
};

const removeComment = (id) => {
  commentFeed.value = commentFeed.value.filter((comment) => comment.id !== id);
};

// Watchers to detect changes in parent props and adjust sorting/filtering accordingly
watch(
  () => props.parentFilter,
  (newVal) => {
    sortComments(selectedSortMethod.value); // Re-sort based on new filter
  }
);

watch(
  () => props.parentSort,
  (newVal) => {
    selectedSortMethod.value = newVal;
    fetchComments(); // Re-fetch comments based on new sort
  }
);

//If it changed, get the comment feed
watch(commentCount, (newCount, oldCount) => {
  // Check if the comment count has changed
  if (newCount !== oldCount) {
    // Fetch the comment feed if the count is greater than 0
    getCommentFeed();
  }
});

watch(
  () => props.loadMore,
  (newVal) => {
    getCommentFeed();
  });

const commentToPostMap = computed(() => {
  const map = {};
  for (const comment of commentFeed.value) {
    map[comment.id] = comment;
  }
  return map;
});

// Check if the feed is narrow (i.e. depth >= 5)
const isNarrow = computed(() => {
  return props.depth >= 7;
});

//DOM Binding and management
const shouldShowLoadMoreButton = computed(() => {
  const shouldShow = commentFeed.value.length < props.parentItem.postEngagement.commentCount;

  return shouldShow;
});


const navigateToContent = (postId) => {
  router.push(`/post/${postId}`);
};

let lastPage = 2;
let currentPage = 1;

async function getCommentFeed() {
  if (currentPage > lastPage) {
    return;
  }
  if (currentPage > 1) {
    loadingMore.value = true;
  }
  try {
    const { last } = await postStore.fetchCommentUlids(props.parentItem.id, selectedSortMethod.value, perPage.value, currentPage);

    currentPage += 1;
    lastPage = last;

    if (!props.detailsShow) {
      emit("scroll-into-view");
      
    }
    
  } catch (error) {
    console.error(error);
  } finally {
    hasCommentFeed.value = true;
    loading.value = false;
    loadingMore.value = false;
  }
}

watch (
  () => selectedSortMethod.value,
  async (newVal) => {
      loading.value = true;
      currentPage = 1;
      await getCommentFeed();     
  }
);

watch (
  () => commentFeed.value.length,
  async (newVal) => {
    if (!props.detailsShow) {
    emit("scroll-into-view");
    }
  }
);

</script>

<style scoped>
.text-primary {
  color: var(--primary-color);
}
</style>
